import styled from 'styled-components';
// import * as map from './Map';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0;

  @media(min-width: 480px) {
    padding: 44px 55px 0;
  }

  @media(min-width: 800px) {
    padding: 44px 68px 0;
  }

  @media(min-width: 1080px) {
    flex-direction: row;
  }

  @media(min-width: 1600px) {
    padding: 64px 80px 0;
  }
`;

export const Lk = styled.div`
  @media(min-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 310px;
    width: 310px;
  }

  > svg {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const Content = styled.div`
  margin-left: 0;
  margin-top: 15px;

  @media(min-width: 1080px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }

  @media(min-width: 1600px) {
    margin-left: 85px;
  }
`;

export const How = styled.div`
  max-width: 500px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 50px;

  @media(min-width: 1080px) {
    margin-top: 0;
  }
`;

export const Options = styled.div`
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  @media(min-width: 530px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media(min-width: 1400px) {
    flex-wrap: nowrap;
  }

  > div {
    margin-top: 60px;
    margin-right: 0;

    &:last-of-type {
      margin-right: 0;
    }

    @media(min-width: 680px) {
      margin-right: 30px;
    }

    @media(min-width: 740px) {
      margin-top: 40px;
    }

    @media(min-width: 1280px) {
      margin-right: 50px;
    }

    @media(min-width: 1600px) {
      margin-right: 100px;
    }
  }
`;

export const Jaw = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 19px;
  font-weight: bold;
`;

export const JawIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 80px;
  background: ${props => props.theme.blue};
  border-radius: 50%;
  margin-right: 13px;

  > svg {
    fill: #fff;
    height: 32px;
  }
`;

export const OptionPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 210px;

  @media(min-width: 1400px) {
  }
  
  b {
    display: block;
    font-size: 24px;
    margin-top: 26px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
  }

  > img {
    margin-top: 34px;
    margin-left: -12px;
    width: calc(100% + 12px);
  }
`;

export const OptionInvoice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 210px;

  button {
    width: 100%;
    height: 48px;
    font-size: 18px;
    border-width: 3px;
    margin-top: 40px;

    @media(min-width: 740px) {
      margin-top: 60px;
    }
  }

  > svg {
    max-width: 160px;
    margin-top: 40px;

    @media(min-width: 740px) {
      margin-top: 60px;
    }
  }
`;

export const InvoicePreview = styled.img`
  display: block;
  max-width: 100%;
  margin: auto;
`;

export const OptionServiceCenter = styled.div`
  width: 100%;

  @media(min-width: 740px) {
    width: 100%;
  }

  @media(min-width: 1400px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    margin-top: 0;
  }
`;

export const ServiceCenter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @media(min-width: 740px) {
    justify-content: flex-start;
  }

  > div:first-child {
    margin-right: 30px;
  }
`;

export const CitySelect = styled.div`
  flex: 1;
  min-width: 190px;
  max-width: 270px;
  margin-top: 22px;

  label {
    display: block;
    color: #A3A3A5;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .ant-select {
    width: 100%;
  }
`;

// export const MapPreview = styled(map.MapPreview)``;
// export const MapFull = styled(map.MapFull)``;

export const MapWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-width: 100%;
  min-height: 400px;
  margin: 55px -32px -40px;

  @media(min-width: 480px) {
    margin-right: -55px;
    margin-left: -55px;
  }

  @media(min-width: 800px) {
    min-height: 300px;
    margin-right: -68px;
    margin-left: -68px;
    margin-bottom: 0;
  }

  @media(min-width: 1080px) {
    justify-self: flex-end;
    align-self: flex-end;
  }

  @media(min-width: 1400px) {
    width: calc(100% + 68px);
    margin-right: -68px;
    margin-left: 0;
  }

  @media(min-width: 1600px) {
    width: calc(100% + 80px);
    margin-right: -80px;
    margin-top: 80px;
  }

  > * {
    padding-top: 0!important;
  }
`;
