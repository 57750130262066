import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  black: '#2B314F',
  blue: '#5492F2',
  blueSaturated: '#0E94F5',
  orange: '#679940',
  blueRGB: 'rgb(42, 92, 170)',
  greenRGB: 'rgb(103, 153, 64)',
  fontFamily: 'PT Sans Regular',
};

export const Styles = createGlobalStyle`
  * {
    box-sizing: border-box;

    /* &::-webkit-scrollbar {
      width: 6px;
      background-color: #fff;
      border: 1px solid #eee;

      &-thumb {
        background: #c9d2e2; 
      }
    } */
  }

  body, h1, h2, h3, h4, h5, h6 {
    color: #2B314F;
    font-family: PT Sans Regular;
  }
  .woot-widget-bubble.woot-elements--right {
    display: none;
    }
  
  a {
    color: ${(props) => props.theme.black};

    &:hover {
      color: ${(props) => props.theme.greenRGB};
    }
  }

  .ant {
    &-btn {
      border-radius: 2em;
      background-color: #fff;
      font-weight: 500;
      color: ${(props) => props.theme.black};
      border-width: 2px;
      font-family: PT Sans Regular;

      &-primary {
        border-color: ${(props) => props.theme.orange};

        &:hover, &:active, &:focus {
          color: ${(props) => props.theme.black};
          border-color: ${(props) => props.theme.greenRGB};
          background-color: #ffeec7;
        }
      }
    }

    &-checkbox-wrapper {
      font-family: PT Sans Regular;
      font-size: 13px!important;
      line-height: 15px;
      color: #8F9091;

      .ant-checkbox {
        margin-right: 0.2rem;
      }
    }

    &-select {
      &-selector {
        font-family: PT Sans Regular;
        border-color: #CBCBCB!important;
        border-radius: 4px!important;
        height: auto!important;
      }

      &-selection-item {
        font-family: PT Sans Regular;
        margin: 4px 0 3px;
        line-height: 1.5em!important;
        font-weight: 500;
        white-space: pre-wrap;

        &:after {
          font-family: PT Sans Regular;          
          display: none!important;
        }
      }

      &-arrow svg {
        fill: ${(props) => props.theme.greenRGB};
      }
    }

    &-popover-buttons {
      button {
        border-width: 1px;
      }
    }

    &-alert {
      border-radius: 5px;
    }

    &-message {
      z-index: 1023;
    }
  }

  
  .ant-input{
    border-color: rgb(103, 153, 64);
    font-family: PT Sans Regular;    
  }
  .ant-input:hover{
    border-color: rgb(103, 153, 64) !important;
  }
  .ant-input:focus, .ant-input-focused{
    border-color: rgb(103, 153, 64);
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important;
  }
  .ant-select-arrow svg {
    fill: rgb(103, 153, 64);
}
.ant-select-selector{
  border-color: rgb(103, 153, 64) !important
}
    
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
}
 
  .ant-picker-focused, .ant-picker:hover {
    border-color: ${(props) => props.theme.greenRGB};
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
  }

  .ant-picker {
    border-color: ${(props) => props.theme.greenRGB};
  }
  .ant-picker-range .ant-picker-active-bar {
    background: ${(props) => props.theme.greenRGB};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #8dc63f !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #8dc63f !important;
}

  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #1f9643e5 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #a3cf62 !important;
}

}

`;
