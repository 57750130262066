import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px;

  @media (min-width: 900px) {
    justify-content: flex-start;
  }
`;

export const ContractZoom = styled.div`
  display: none;
  position: absolute;
  right: 22px;
  width: 30px;
  height: 30px;
  z-index: 1;
  cursor: pointer;

  > svg {
    fill: ${(props) => props.theme.orange};
  }
`;

export const ContractData = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 290px;
  padding: 25px;
`;

export const ContractParam = styled.div`
  width: 47%;
  margin-top: 22px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black};

  u {
    display: block;
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    color: #a3a3a5;
  }
`;

export const ContractType = styled(ContractParam)`
  width: calc(100% - 35px);
  margin-top: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  u {
    font-weight: normal;
  }
`;

export const PrintNumber = styled(ContractParam)`
  width: 100%;
`;

export const ContractNumber = styled(ContractParam)`
  width: 100%;
`;

export const ContractConnections = styled.div`
  flex: 1;
  background-color: #f3f3f3;
  padding: 25px 25px;
  overflow: hidden;
`;

export const Contract = styled.div<{ maximized?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.maximized ? '1 1 100%' : 'none')};
  overflow: hidden;
  max-width: 300px;
  margin: 20px;
  border-radius: 20px;
  border: 1px solid #679940;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${(props) =>
    props.maximized
      ? 'linear-gradient(180deg, rgba(103,153,64,1) 0%, rgba(255,255,255,1) 30.73%)' //linear-gradient(180deg, #2a5caa 0%, #FFFFFF 30.73%)
      : '#fff'};

  @media (min-width: 880px) {
    flex-direction: row;
    max-width: fit-content;
    order: ${(props) => (props.maximized ? -1 : 'auto')};
  }

  ${ContractZoom} {
    display: ${(props) => (props.maximized ? 'block' : 'none')};
    top: 22px;

    @media (min-width: 880px) {
      top: ${(props) => (props.maximized ? 'auto' : '22px')};
      bottom: ${(props) => (props.maximized ? '22px' : 'auto')};
    }
  }

  &:hover {
    border: none;
    background: linear-gradient(180deg, rgba(103,153,64,1) 0%, rgba(255,255,255,1) 30.73%); //linear-gradient(180deg, #2a5caa 0%, #ffffff 30.73%)

    ${ContractZoom} {
      display: block;
    }
  }
`;
