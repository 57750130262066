import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import { Spin, notification } from 'antd';
import srv from '../../../../services';

interface Props {
  showSpin?: boolean;
  onSuccess: (message?: string) => void;
}

export default function EmailConfirm({ showSpin = true, onSuccess }: Props) {
  const { isLoading } = useEmailConfirm(onSuccess);

  if (showSpin && isLoading) return <Spin />;

  return null;
}

function useEmailConfirm(onSuccess: Props["onSuccess"]) {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const { approveKey } = useParams<any>();
  const {
    isLoading, isSuccess, data: message, error
  } = useQuery(['changeEmailConfirm', approveKey], () => srv.changeUserEmailConfirm(approveKey));

  useEffect(() => {
    if (isSuccess) {
      queryClient.refetchQueries('user');
      onSuccess(message);
      push('/settings');
    }
  }, [isSuccess, message, queryClient, onSuccess, push]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: 'bottomRight',
        duration: 7,
      });
      push('/settings');
    }
  }, [error, push]);

  return { isLoading, error };
}
