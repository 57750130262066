import styled from "styled-components";
import * as map from "./Map";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  //   @media (min-width: 480px) {
  //     padding: 0;
  //   }

  //   @media (min-width: 800px) {
  //     padding: 0;
  //   }

  //   @media (min-width: 1080px) {
  //     flex-direction: row;
  //   }

  //   @media (min-width: 1600px) {
  //     padding: 0;
  //   }
  //
`;

export const Content = styled.div`
  margin-left: 0;
  margin-top: 30px;

  // @media (min-width: 1080px) {
  //   flex: 1;
  //   display: flex;
  //   flex-direction: column;
  //   margin-left: 30px;
  // }

  // @media (min-width: 1600px) {
  //   margin-left: 85px;
  // }
`;

export const OptionServiceCenter = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  // @media (min-width: 740px) {
  //   width: 100%;
  // }

  // @media (min-width: 1400px) {
  //   flex: 1;
  //   display: flex;
  //   justify-content: flex-start;
  //   width: auto;
  //   margin-top: 0;
  //   max-height: 150px;
  // }

  .ant-select-selector{
    border-color: rgb(103, 153, 64) !important
  }
      
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }

  .ant-input{
    border-color: rgb(103, 153, 64)
  }
  .ant-input:focus{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }
  .ant-select-arrow svg {
    fill: rgb(103, 153, 64);
}
.ant-picker-focused {
  border-color: ${(props) => props.theme.greenRGB};
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
  box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
}

.ant-picker {
  border-color: ${(props) => props.theme.greenRGB};
}
.ant-picker-range .ant-picker-active-bar {
  background: ${(props) => props.theme.greenRGB};
}
`;

export const PictureLocation = styled.div`
  display: flex;
  padding: 0;

  svg {
    margin: 0 30px;
  }

  b {
    width: 100px;
    margin-top: 10px;
    margin-right: 15px;
  }
`;

export const CitySelect = styled.div`
  flex: 1;
  min-width: 190px;
  max-width: 270px;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 30px;

  label {
    display: block;
    color: #a3a3a5;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .ant-select {
    width: 100%;
  }
`;

export const MapPreview = styled(map.MapPreview)``;
export const MapFull = styled(map.MapFull)``;

export const MapWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-width: 100%;
  min-height: 400px;
  margin: 0;

  // @media (min-width: 480px) {
  //   margin-right: -55px;
  //   margin-left: -55px;
  // }

  // @media (min-width: 800px) {
  //   min-height: 300px;
  //   margin-right: -68px;
  //   margin-left: -68px;
  //   margin-bottom: 0;
  // }

  // @media (min-width: 1080px) {
  //   justify-self: flex-end;
  // }

  // @media (min-width: 1400px) {
  //   width: calc(100% + 68px);
  //   margin-right: -68px;
  //   margin-left: 0;
  // }

  // @media (min-width: 1600px) {
  //   width: calc(100% + 80px);
  //   margin-right: -80px;
  // }

  // > * {
  //   padding-top: 0 !important;
  // }
`;
