import axios from 'axios';

export async function checkPhone(phone): Promise<Models.PhoneInfo> {
  const res = await axios.get(`phone/check/${phone}`);
  return res.data;
}

export async function pinSend(phone) {
  const res = await axios.get(`phone/pinsend/${phone}`);
  return res.statusText;
}

export async function pinApprove(pincode) {
  const res = await axios.post('phone', pincode);
  return res.statusText;
}