import React, { useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { Button, Alert, Form, Input } from "antd";
import { FormInstance, RuleObject } from "antd/lib/form";
import srv from "../../../../services";
import { BaseForm } from "../../../_common";
import { SaveButton } from "./PasswordSettings.styled";

interface Props {
  onFinish: (message?: string) => void;
}

export default function PasswordSettings({ onFinish }: Props) {
  const { isSubmiting, error, validatePasswordConfirm, ...formProps } =
    useForm(onFinish);

  return (
    <BaseForm layout="vertical" {...formProps}>
      <Form.Item
        name="password"
        label="Старый пароль"
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="newValue"
        label="Новый пароль"
        rules={[{ required: true }]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Подтверждение нового пароля"
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <footer>
        <SaveButton htmlType="submit" type="primary" loading={isSubmiting}>
          Сохранить
        </SaveButton>

        <Button onClick={() => onFinish()}>Отмена</Button>
      </footer>
    </BaseForm>
  );
}

function useForm(onSuccess: Props["onFinish"]) {
  const {
    mutate: submit,
    isLoading: isSubmiting,
    isSuccess,
    error,
    data: message,
    reset,
  } = useMutation(srv.changeUserPassword);

  const validatePasswordConfirm = useCallback(
    ({ getFieldValue }: FormInstance): RuleObject => ({
      validator(_, value) {
        if (!value || value === getFieldValue("newValue")) {
          return Promise.resolve();
        }
        return Promise.reject("Пароль и подтверждение не совпадают");
      },
    }),
    []
  );

  const onFinish = useCallback(
    (values) => {
      submit(values);
    },
    [submit]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess(message);
      reset();
    }
  }, [isSuccess, message, reset, onSuccess]);

  return {
    isSubmiting,
    error,
    validatePasswordConfirm,
    onValuesChange,
    onFinish,
  };
}
