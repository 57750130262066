import { useQueryClient } from "react-query";
import { SubmitButton } from "../PaymentDocuments/PaymentDocuments.styled";
import { Popconfirm } from "antd";
import React from "react";
import { ContentWrapper } from "../../_common";
import {
  Content,
  Contract,
  ContractData, ContractNumber,
  ContractType,
} from "../Contracts/Contracts.styled";
import srv from "../../../services";

const Notifications = () => {
  const notifications = useQueryClient().getQueryData<any>("notifications");
  const queryClient = useQueryClient();

  const base64toBlob = (data: string) => {
    if (!data) {
      return new Blob();
    }

    try {
      const bytes = atob(data);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], {type: 'application/pdf'});
    } catch (e: any) {
      console.log('error')
    }
  };

  return (
    <>
      <ContentWrapper>
        <Content>
          {notifications?.map((notification) => (
            <Contract
              key={notification.id}
            >

              <ContractData>

                <ContractType>
                  <u>Тип</u>
                </ContractType>

                <div style={{
                  display:'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  wordBreak: 'break-word',
                  width: '220px',
                  fontWeight: '600',
                }}> {notification.typeName}</div>

                <ContractNumber>
                  <u>Номер договора</u> {notification.contractSystemNumber}
                </ContractNumber>

                <ContractNumber>
                  <u>Дата</u>{" "}
                  {notification.documentDate}
                </ContractNumber>

                <ContractNumber>
                  <u>Состояние</u> {notification.isRead ? 'Прочитано' : 'Не прочитано'}
                </ContractNumber>

                <Popconfirm
                  title="Вы уверены?"
                  okButtonProps={{danger: true}}
                  okText="Да"
                  cancelText="Отмена"
                  onConfirm={() => {
                    try {
                      const link = document.createElement("a");
                      link.href = URL.createObjectURL(base64toBlob(notification.fileContent));
                      link.target = "_blank";
                      link.download = notification.typeName;
                      document.body.appendChild(link);
                      link.click();
                      link.remove();

                      srv.setNotificationReadStatus({notificationId: notification.id, isRead: true})
                        .then((res: any) => {
                          queryClient.refetchQueries("notifications");
                          queryClient.refetchQueries("notificationNotReadCount");
                        })
                        .catch(() => console.error('Произошла неизвестная ошибка!'))
                    } catch (e) {
                      console.log('ERROR', e)
                    }
                  }}
                >
                  <SubmitButton>Ознакомиться</SubmitButton>
                </Popconfirm>

              </ContractData>


            </Contract>
          ))}
        </Content>
      </ContentWrapper>
    </>
  )
}

export default Notifications;