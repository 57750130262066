import axios from 'axios'


export async function openPaymentDocument(data) {
  
  const res = await axios.post('doc/getlawsuitdocument',data, {
    responseType: 'blob',
  });
  const blobURL = URL.createObjectURL(res.data);
  window.open(blobURL);
}

export async function paymentDebt(payload) {
  const res = await axios.post('payment/register', payload);
  return res.data
}

export async function getPaymentDocs(payload) {
  const res = await axios.post("paymentDocs/getPaymentDocs", payload)
  return res.data;
}

export async function requestDocumentsStoryPayment(payload){
  const res = await axios.post(`historyPay/getHistoryPay`, payload);
  return res.data;
}