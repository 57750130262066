import React, { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Select, DatePicker, Form, Input, Alert, Popconfirm } from "antd";
import { BaseForm, Col, SubmitButton } from "./PaymentDocuments.styled";
import SuccessModal from "./Modal/SuccessModal";
import srv from "../../../services";
import { green } from "@mui/material/colors";

const { RangePicker } = DatePicker;

export default function PaymentDocuments() {
  const {
    handleCancel,
    documents,
    contracts,
    isLoading,
    visible,
    error,
    form,
    data,
    ...formProps
  } = usePaymentDocuments();

  return (
    <>
      <BaseForm layout="vertical" scrollToFirstError {...formProps} form={form}>
        <Col>
          <Form.Item
            name="contractId"
            label="№ Договора"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Выберите № договора"
              loading={isLoading}
            >
              {contracts?.map((item) => (
                <Select.Option key={item.id} value={item.aisId}>
                  {item.printNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1.3}>
          <Form.Item
            name="period"
            label="Период запроса"
            rules={[{ required: true }]}
          >
            <RangePicker
              allowClear
              format="DD.MM.YYYY"
              style={{ width: "100%",  }}
               
            />
          </Form.Item>
          <Form.Item
            name="documentsType"
            label="Тип запрашиваемого документа"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder="Выберите тип документа"
              loading={isLoading}
            >
              {documents?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1.2}>
          <Form.Item name="eMail" label="E-mail" rules={[{ required: true }]}>
            <Input placeholder="Введите E-mail" />
          </Form.Item>

          {!!error && <Alert type="error" showIcon message={error} />}
          <Popconfirm
            title="Вы уверены?"
            okButtonProps={{ danger: true }}
            okText="Да"
            cancelText="Отмена"
            onConfirm={() => form.submit()}
            disabled={isLoading}
          >
            <SubmitButton loading={isLoading}>Запросить</SubmitButton>
          </Popconfirm>
        </Col>
      </BaseForm>
      <SuccessModal visible={visible} handleCancel={handleCancel} data={data} />
    </>
  );
}

function usePaymentDocuments() {
  const queryClient = useQueryClient();
  const contracts = queryClient.getQueryData<Models.Contract[]>("contracts");
  const documents = queryClient.getQueryData<Models.Dict[]>("documents");
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const {
    data,
    mutate: save,
    isLoading,
    error,
    reset,
  } = useMutation(srv.requestDocuments, {
    onSuccess: () => {
      form.resetFields();
      showModal();
    },
  });

  const onFinish = useCallback(
    (values) => {
      save({
        contractId: values.contractId,
        eMail: values.eMail,
        documentsType: values.documentsType,
        startDate: values.period[0],
        endDate: values.period[1],
      });
    },
    [save]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    onFinish,
    onValuesChange,
    handleCancel,
    data,
    contracts,
    isLoading,
    form,
    error,
    documents,
    visible,
  };
}
