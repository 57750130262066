import styled from 'styled-components';
import * as antd from 'antd';
import * as common from '../../_common';

export const BaseForm = styled(common.BaseForm)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 30px;

  @media (min-width: 1160px) {
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 0;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    background-color: #679940;
  }

   `;

export const Col = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  margin: 0 2%;
  padding-top: 20px;

  &:first-child {
    padding-top: 0;
  }

  @media (min-width: 1160px) {
    padding-top: 0;
  }
 `;

export const SubmitButton = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  color: #fff;
  margin-top: 30px;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
`;

export const Modal = styled(antd.Modal)`
  .ant-modal-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  .ant-modal-footer {
    text-align: center;
    border: none;
  }
  .ant-modal-body {
    padding: 20px 20px 0 20px;
  }
`;

export const ButtonCancel = styled(antd.Button)`
  margin: auto;
  width: 150px;
  font-size: 18px;
  line-height: 18px;
  color: #2b314f;
`;
