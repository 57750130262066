import React from "react";
import { Switch, Route } from "react-router-dom";
import MapContent from "./MapContent";

export default function OfficeMap() {
  return (
    <>
      <Switch>
        <Route path="/map" component={MapContent} />
      </Switch>
    </>
  );
}
