import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Contracts from "./Contracts";
import StoryPayment from "./StoryPayment";
import { ContentNav } from "../_common";

export default function Payment() {
  return (
    <>
      <ContentNav
        links={[
          { name: "Оплата", path: "/payment/agreements" },
          {
            name: "История оплат",
            path: "/payment/story-payment",
          },
        ]}
      />
      <Switch>
        <Route path="/payment/agreements" component={Contracts} />
        <Route path="/payment/story-payment" component={StoryPayment} />
        <Route render={() => <Redirect to="/payment/agreements" />} />
      </Switch>
    </>
  );
}
