import React, { useCallback, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { Alert, Button, Select } from 'antd';
import { SignoutIcon, ManhattanImage, CompanyIcon } from '../../_assests';
import { Uid } from '../..';
import srv from '../../../services';
import {
  Spin,
  Errors,
  Wrapper,
  Logo,
  MenuStyled,
  MenuItem,
  Logout,
  Main,
  Content,
  HeaderWrapper,
  Header,
  Title,
  Widgets,
  Company,
  Avatar,
  Manhattan,
  Footer,
} from './Layout.styled';
import ChatwootWidget from '../../_common/ChatwootWidget';
import { theme } from '../theme';

interface Props {
  isLoading?: boolean;
  error?: unknown;
  navigation: {
    url: string;
    name?: string;
    icon?: any;
    component: React.FunctionComponent;
    featured?: boolean;
  }[];
}

export default function MainLayout({
  isLoading = false,
  error,
  navigation,
}: Props) {
  const { userNav, activeNavItem, contragent, contragentList } =
    useNav(navigation);
  const { user, onLogout } = useUser();
  const queryClient = useQueryClient();

  const { mutate: updateContragent } = useMutation(srv.setSelectContragent, {
    onSuccess: () => {
      queryClient.refetchQueries('contragent');
      queryClient.refetchQueries('appeals');
      queryClient.refetchQueries('contracts');
      queryClient.clear();
    },
  });

  const onUpdateContragent = useCallback(
    (defaultUid: string) => {
      updateContragent(defaultUid);
    },
    [updateContragent]
  );

  const { pathname } = useLocation();

  if (isLoading) return <Spin size="large" />;

  if (error)
    return (
      <Errors>
        <Alert
          type="error"
          showIcon
          message="Произошла ошибка при загрузке данных"
          description={error}
        />

        <Button onClick={() => window.location.reload()}>
          Повторить попытку
        </Button>
      </Errors>
    );

  return (
    <Wrapper>
      <MenuStyled>
        <a href="https://yugra-ecology.ru/">
          <Logo
            src="/logo_vostok.png"
            alt="АО «ЭК «Восток»"
            style={{
              width: '80px',
              height: '80px',
              //marginBottom: '40px',
              marginTop: '-10px',
              marginLeft: '20px',
              //marginRight: '-100px',
            }}
          />
        </a>
        {userNav.map((item) => {
          if (!item.name) return null;
          return (
            <MenuItem
              key={item.url}
              to={item.url}
              title={item.name}
              featured={item.featured}
            >
              {item.icon}
            </MenuItem>
          );
        })}

        <Logout to="/" activeClassName="" title="Выход" onClick={onLogout}>
          <SignoutIcon />
        </Logout>
      </MenuStyled>

      <Main>
        <HeaderWrapper>
          <Header>
            <Title>
              <Logo src="/logo_vostok.png" alt="АО «ЭК «Восток»" />
              <h1>{activeNavItem?.name}</h1>
            </Title>

            {!!user?.uid && (
              <Widgets>
                <Company>
                  <Avatar>
                    {/*<CompanyIcon />*/}
                    Выбор контрагента:
                  </Avatar>

                  <Select
                    value={contragent?.companyShortName}
                    style={{
                      width: '100%',
                      marginTop: '5px',
                      color: '#2a5caa',
                    }}
                    bordered={false}
                    onChange={(value) => {
                      onUpdateContragent(value);
                    }}
                  >
                    {contragentList.map((company) => {
                      return (
                        <Select.Option
                          value={company.uid}
                          key={company.id}
                          title={company.companyName}
                        >
                          {company.companyShortName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Company>
              </Widgets>
            )}
          </Header>
        </HeaderWrapper>

        <Content key={pathname}>
          <Switch>
            {userNav.map((item) => (
              <Route
                key={item.url}
                path={item.url}
                component={item.component}
              />
            ))}

            {!!userNav[0] && (
              <Route render={() => <Redirect to={userNav[0].url} />} />
            )}
          </Switch>

          {!!user?.uid && (
            <Manhattan>
              <ManhattanImage />
            </Manhattan>
          )}
          <Footer />
        </Content>
      </Main>
      {/* <ChatwootWidget /> */}
    </Wrapper>
  );
}

function useNav(navigation: Props['navigation']) {
  const { pathname } = useLocation();
  const contragent =
    useQueryClient().getQueryData<Models.Contragent>('contragent');

  const contragentList =
    useQueryClient().getQueryData<Models.ContragentList[]>('contragentList');

  const user = useQueryClient().getQueryData<Models.User>('user');

  const userNav = useMemo(() => {
    // if (contragent?.contragentType === "Юридическое лицо") {
    //   navigation.splice(1, 1);
    //   return navigation;
    // } else {
    return navigation.map((item) => {
      if (!user?.uid)
        return {
          ...item,
          component: Uid,
        };
      return item;
    });
    // }
  }, [user, navigation, contragent?.contragentType]);

  const activeNavItem = useMemo(() => {
    return navigation.find((item) => pathname.includes(item.url));
  }, [navigation, pathname]);

  return { userNav, activeNavItem, contragent, contragentList };
}

function useUser() {
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData<Models.User>('user');

  const onLogout = useCallback(() => {
    localStorage.removeItem('token');
    queryClient.resetQueries('user');
    queryClient.clear();
  }, [queryClient]);

  return { user, onLogout };
}
