import styled from 'styled-components';
import * as antd from 'antd';
import _Slider from 'react-slick';

export const Spin = styled(antd.Spin)`
  margin-top: 0.44rem;
  margin-left: 0.55rem;
`;

export const Slider = styled(_Slider)<{ noArrows?: boolean }>`
  @media (min-width: 880px) {
    padding: ${(props) => (props.noArrows ? 0 : '0 40px')};
  }

  .slick {
    &-arrow {
      display: none !important;
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0;
      z-index: 1;
      cursor: pointer;

      rect {
        fill: ${(props) => props.theme.blue};
      }

      circle {
        stroke: ${(props) => props.theme.blue};
      }

      @media (min-width: 880px) {
        display: block !important;
      }
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }

    &-disabled {
      filter: grayscale(0.7) opacity(0.5);
      cursor: default;
    }

    &-dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0 !important;
      margin-top: 22px;
      margin-bottom: 0;

      @media (min-width: 880px) {
        display: none !important;
      }

      li {
        margin-right: 8px;

        &:last-of-type {
          margin-right: 0;
        }

        button {
          display: block;
          width: 12px;
          height: 12px;
          border: 3px solid ${(props) => props.theme.blue};
          color: transparent;
          font-size: 0;
          padding: 0;
          border-radius: 50%;

          &:focus {
            outline: 0;
          }
        }

        &.slick-active button {
          background-color: ${(props) => props.theme.blue};
        }
      }
    }
  }
`;
export const ItemWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
`;

export const Item = styled.div`
  padding: 0 5px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
`;

export const Param = styled.div`
  max-width: 250px;
  margin-top: 22px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black};

  u {
    display: block;
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    color: #a3a3a5;
  }
`;
export const ParamWrapper = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: column;
  margin: 10px;
`;
