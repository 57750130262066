import styled from 'styled-components';
import * as antd from 'antd';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  background: #fff;
  padding: 30px 20px;

  @media(min-width: 480px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 800px) {
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0;
  }

  @media(min-width: 1400px) {
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media(min-width: 1600px) {
    padding-top: 50px;
    padding-left: 80px;
    padding-right: 80px;
  }
`;

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -18px;
  margin-left: -16px;
`;
