import { StepAuthorization, BackButton } from "../../Forms.styled";

const StepForAuthorization = ({ onBack }) => {
  return (
    <StepAuthorization>
      <BackButton size="large" onClick={onBack}>
        Назад
      </BackButton>
    </StepAuthorization>
  );
};

export default StepForAuthorization;
