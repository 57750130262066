import styled from 'styled-components';
import * as antd from 'antd';

export const PaymentButton = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  color: #fff;
  width: 200px;
  font-size: 18px;
  line-height: 18px;
    position: relative;
    z-index: 10001;

    &:hover {
    color: #313149;
    background-color: ${(props)=>props.theme.greenRGB}
  }
`;

export const SumPayBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 45px;
    position: relative;
    z-index: 10001;
`;

export const Description = styled.span`
  margin-right: 20px;
  font-size: 16px;
`;
