import axios from 'axios';

export async function getRegions(): Promise<Models.Dict[]> {
  const res = await axios.get('dictionary/region');
  return res.data;
}

export async function getServiceCenters(regionId: number): Promise<Models.ServiceCenter[]> {
  const res = await axios.get('point', { params: { regionId } });
  return res.data;
}