import axios from 'axios';

export async function getFeature(): Promise<Models.Feature> {
  const res = await axios.get('feature');
  return res.data;
}

export async function markRead(entity: string, id: number) {
  const res = await axios.post('feature/mark', { entity, id });
  return res.data;
}
