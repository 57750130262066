import moment from "moment";
import { TablePayment } from "./TableStoryPayment.styled";

interface Props {
  data?: any;
  isLoading?: boolean;
}

const TableStoryPayment = ({ data, isLoading }: Props) => {
  const columns = [
    {
      id: 1,
      title: "Дата документа",
      dataIndex: ["documetDate"],
      render: (_, record) => {
        return <>{moment(record.documetDate).format("DD.MM.YYYY")}</>;
      },
    },
    {
      id: 2,
      title: "Номер документа",
      dataIndex: ["documentNumber"],
    },
    {
      id: 3,
      title: "Сумма платежа, ₽",
      dataIndex: ["paymentAmount"],
    },
  ];

  return (
    <TablePayment
      rowKey={(record) => record.id}
      columns={columns}
      rowClassName={() => "editable-row"}
      dataSource={data}
      pagination={false}
      loading={isLoading}
      style={{ marginTop: "20px" }}
    />
  );
};

export default TableStoryPayment;
