import styled from 'styled-components';
import * as router from 'react-router-dom';
import * as antd from 'antd';
import _Footer from '../Footer';



export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
`;

export const Errors = styled.div`
  padding: 0.5rem;

  @media (min-width: 640px) {
    padding: 2rem;
  }

  button {
    display: block;
    margin: 1rem auto;
  }
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #e7ebf3;
`;

export const Logo = styled.img`
  height: 120px;
`;

export const MenuStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 65px;
  background-color: #fff;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;

  @media (min-width: 800px) {
    flex-direction: column;
    align-items: stretch;
    left: 0;
    top: 0;
    height: 100vh;
    width: 120px;
    padding: 20px 0 40px;
    box-shadow: 0px 0.2rem 0.3rem rgba(0, 0, 0, 0.2);
    overflow: auto;
  }

  ${Logo} {
    display: none;
    margin: 0 auto 70px;

    @media (min-width: 800px) {
      display: block;
    }
  }
`;

export const MenuItem = styled(router.NavLink)<{ featured?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  
  @media(min-width: 800px) {
    margin-top: 10px;
    margin-bottom: 60px;
    margin-left: 0;
  }

  &:last-of-type {
    margin-left: 0;
  }

  &:hover, &.active {
    stop {
      transition: .5s ease;
    }
    stop:first-child {
      stop-color: #8dc63f;
    }
    stop:last-child {
      stop-color: #8dc63f;
    }
  }
}

  &.active::after {
    @media(min-width: 800px) {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 4px;
      height: 100%;
      border-radius: 4px;
      background-color: #8dc63f;
    }
  }

  &:before {
    content: '';
    display: ${(props) => (props.featured ? 'block' : 'none')};
    position: absolute;
    left: 65%;
    top: -5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #F72A2A;
  }

  svg {
    max-width: 40px;
    max-height: 30px;
    fill: #679940;

    @media(min-width: 800px) {
      max-height: 40px;
    }
  }
`;

export const Logout = styled(MenuItem)`
  order: -1;

  @media (min-width: 800px) {
    order: initial;
    margin-top: auto;
    margin-bottom: 0;
  }
`;

export const Main = styled.div`
  padding-bottom: 100px;
  
  @media (min-width: 800px) {
    margin-left: 150px;
    padding-bottom: 0;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #e7ebf3;
  
  @media (min-width: 800px) {
    position: sticky;
    top: 0;
    z-index: 3;
    height: 135px;
    margin-left: -10px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: ${(props)=>props.theme.blueRGB};
  
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    height: 100px;
    margin-left: 10px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 0 0 0 20px;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 1400px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 1600px) {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 28px;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
 
  @media (min-width: 800px) {
    padding: 0;
    margin-right: 30px;
    border-radius: 0;
    box-shadow: none;
  }

  h1 {
    font-size: 30px;
    line-height: 32px;
    margin: 0;
    color: ${(props) => (props.theme.blueRGB)};
  }

  ${Logo} {
    margin-right: 28px;
    @media (min-width: 800px) {
      display: none;
    }
  }
`;

export const Widgets = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 28px 18px;

  @media (min-width: 800px) {
    justify-content: flex-end;
    padding: 0;
  }

  > * {
    margin-right: 33px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Date = styled.div`
  b {
    display: block;
    font-weight: 500;
  }
`;

export const Company = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  max-width: 470px;
  
  @media (min-width: 1160px) {
    max-width: 470px;
  }

  b {
    display: block;
    font-weight: 500;
    line-height: 16px;
    margin: 5px 0;
  }
  .ant-select-arrow svg {
    fill: #2a5caa !important;
}
`;

export const Avatar = styled.div`
  min-width: 190px;
  height: 30px;
  margin-right: 20px;
  align-self: center;
  font-size: 20px;
  font-weight: 600;

  > * {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  overflow-x: visible;
  
  @media (min-width: 800px) {
    position: relative;
    border-radius: 20px 0 0 0;

    @media (min-height: 600px) {
      height: calc(100vh - 135px);
      overflow-y: scroll;
    }
  }
`;

export const Manhattan = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    width: 100%;
    margin-top: auto;
  }
`;

export const Footer = styled(_Footer)``;
