import styled from 'styled-components';
import * as antd from 'antd';

export const Alert = styled(antd.Alert)`
  margin-bottom: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > * {
    margin-top: 0!important;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  p {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #8F9090;
  }
  
  @media (max-width: 576px) {
    margin-bottom: 8px;
    p {
      display: none;
    }
  }
`;

export const Agreement = styled.div`
  .ant-checkbox-inner {
    margin-top: 4px!important;
  }
`;
