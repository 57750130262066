import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Input } from "antd";

interface Props {
  onChange?: (val) => void;
  autoFocus?: any;
}

export default function PhoneInput({ onChange, ...restProps }: Props) {
  const [value, setValue] = useState("");

  const justMounted = useRef(true);

  useEffect(() => {
    if (justMounted.current) {
      justMounted.current = false;
      return;
    }

    const unmaskedValue = [" ", "+", "-", "_", "(", ")"].reduce((acc, x) => {
      return acc.replaceAll(x, "");
    }, value);

    onChange(unmaskedValue.slice(1));
  }, [value, onChange]);

  return (
    <ReactInputMask
      mask="+7 (\999)-999-99-99"
      onChange={(e) => setValue(e.target.value)}
      value={value}
      {...restProps}
    >
      {(inputProps) => <Input {...inputProps} />}
    </ReactInputMask>
  );
}
