import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem auto;
  max-width: 23rem;

  .ant-btn {
    display: block;
    width: 100%;
    margin: 1rem auto;
  }

  .ant-spin {
    margin-top: 1rem;
  }
`;
