import styled from 'styled-components';
import * as antd from 'antd';

export const SaveButton = styled((props) => (
  <antd.Button type="primary" {...props} />
))`
  background-color: #679940;
  color: #313149;
  font-size: 18px;
  line-height: 18px;
`;
