import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { Form, Alert, Input, Checkbox } from 'antd';
import srv from '../../../services';
import PinInput from './PinInput';
import PasswordInput from './PasswordInput';
import StepForAuthorization from './StepForAuthorization';
import {
  BaseForm,
  Title,
  Description,
  SubmitButton,
  Error,
  NoRegistration,
} from '../Forms.styled';
import ReactInputMask from 'react-input-mask';

interface Props {
  onForgot: () => void;
}

export default function SignIn({ onForgot }: Props) {
  const { isLoading, error, phoneInfo, message, form, onBack, ...formProps } =
    useAuth();

  if (!isLoading && phoneInfo && !phoneInfo.registred)
    return (
      <PinInput
        message={message}
        phone={form.getFieldValue('login')}
        onBack={onBack}
      />
    );

  if (!isLoading && phoneInfo && phoneInfo.registred)
    return (
      <PasswordInput
        onForgot={onForgot}
        phone={form.getFieldValue('login')}
        onBack={onBack}
      />
    );
  if (error) {
    return (
      !!error && (
        <Error>
          <Alert type="error" message={error} />
          <StepForAuthorization onBack={onBack} />
        </Error>
      )
    );
  }
  return (
    <BaseForm form={form} layout="vertical" size="large" {...formProps}>
      <Title>
        <Description>
          Введите номер мобильного телефона, который Вы указывали при заключении
          договора
        </Description>
      </Title>

      <Form.Item
        name="login"
        label="Телефон"
        initialValue={window.location.pathname.replace('/', '')}
        rules={[{ required: true }]}
      >
        <ReactInputMask mask="+7 \999 999-99-99" autoFocus>
          {(inputProps) => <Input {...inputProps} />}
        </ReactInputMask>
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Запомнить меня</Checkbox>
      </Form.Item>
      <SubmitButton
        htmlType="submit"
        size="large"
        loading={isLoading}
        style={{ color: 'white' }}
      >
        Войти в личный кабинет
      </SubmitButton>
    </BaseForm>
  );
}

function useAuth() {
  const [form] = Form.useForm();
  const [phoneInfo, setPhoneInfo] = useState<Models.PhoneInfo>(null);
  const [message, setMessage] = useState(null);

  const {
    mutate: pinSend,
    isLoading: isLoadingPinSend,
    error: errorPinSend,
    reset: resetPinSend,
  } = useMutation(srv.pinSend, {
    onSuccess: (data) => {
      setMessage(data);
    },
  });

  const {
    mutate: checkPhoneNumber,
    isLoading: isLoadingCheckPhone,
    error,
    reset,
  } = useMutation(srv.checkPhone, {
    onSuccess: (data, phone) => {
      console.log('data', data);

      if (data) {
        setPhoneInfo(data);
        if (data && !data?.registred) {
          pinSend(phone);
        }
      }
    },
  });
  const isLoading = isLoadingPinSend || isLoadingCheckPhone;

  const onBack = useCallback(() => {
    setPhoneInfo(null);
    reset();
    resetPinSend();
  }, [reset, resetPinSend]);

  const onFinish = useCallback(
    ({ login, remember }) => {
      checkPhoneNumber(login, remember);
    },
    [checkPhoneNumber]
  );

  const onValuesChange = useCallback(() => {
    if (error) {
      reset();
    }
    if (errorPinSend) {
      resetPinSend();
    }
  }, [error, reset, errorPinSend, resetPinSend]);

  let errorOut
  if (error === 'Указанный номер телефона не подтвержден, обратитесь в Call центр 8 800 222 11 86' || error === 'Указанный номер телефона не найден, обратитесь в службу поддержки.') {
   errorOut =  <NoRegistration>
      Указанный номер телефона не подтвержден, обратитесь в Call центр 8 800 222 11 86
    </NoRegistration>
  } else {
    errorOut = error
  }
  return {
    isLoading,
    error: errorOut ?? errorPinSend,
    phoneInfo,
    message,
    form,
    onValuesChange,
    onFinish,
    onBack,
  };
}
