import { useCallback } from "react";
import { useQueryClient, useMutation } from "react-query";
import { Alert, Select, Form, Popconfirm } from "antd";
import srv from "../../../services";
import {
  SubmitButton,
  RangePicker,
  Col,
  BaseForm,
} from "./StoryPayment.styled";
import TableStoryPayment from "./TableStoryPayment";

export default function StoryPayment() {
  const { data, form, error, isLoading, isSuccess, ...formProps } =
    useContractsPayment();
  const { contracts } = useDicts();

  return (
    <>
      <BaseForm
        layout="vertical"
        form={form}
        {...formProps}
        style={{
          display: "flex",
        }}
      >
        <Col>
          <Form.Item
            name="contractId"
            label="№ Договора"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Выберите № договора"
              loading={isLoading}
            >
              {contracts?.map((item) => (
                <Select.Option key={item.id} value={item.aisId}>
                  {item.printNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1.3}>
          <Form.Item
            name="period"
            label="Период запроса"
            rules={[{ required: true }]}
          >
            <RangePicker
              allowClear
              format="DD.MM.YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col flex={2.4}>
          <Popconfirm
            title="Вы уверены?"
            okButtonProps={{ danger: true }}
            okText="Да"
            cancelText="Отмена"
            onConfirm={() => form.submit()}
            disabled={isLoading}
          >
            <SubmitButton loading={isLoading}>Запросить</SubmitButton>
          </Popconfirm>
        </Col>
        {!!error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}
      </BaseForm>

      {isSuccess ? (
        <TableStoryPayment data={data} isLoading={isLoading} />
      ) : null}
    </>
  );
}

function useContractsPayment() {
  const [form] = Form.useForm();

  const {
    data,
    mutate: save,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useMutation(srv.requestDocumentsStoryPayment, {
    onSuccess: () => {
      form.resetFields();
    },
  });

  const onFinish = useCallback(
    (values) => {
      save({
        contractId: values.contractId,
        periodStart: values.period[0],
        periodFinish: values.period[1],
      });
    },
    [save]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    data: data?.historyPay,
    form,
    error,
    isLoading,
    isSuccess,
    onFinish,
    onValuesChange,
  };
}

function useDicts() {
  const queryClient = useQueryClient();

  const contracts = queryClient.getQueryData<Models.Contract[]>("contracts");
  const services = queryClient.getQueryData<Models.Dict[]>("services");

  return { contracts, services };
}
