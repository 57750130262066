import React from "react";
import { Divider } from "antd";
import {
  Content,
  Head,
  Text,
  Title,
} from "../../../BroadCast/MeteringDevices/ModalStyled.styled";
import { Modal, ButtonCancel } from "./../PaymentDocuments.styled";
import { Info } from "../../../_assests";

export default function SuccessModal({ visible, handleCancel, data }) {
  return (
    <Modal
      visible={visible}
      maskClosable={false}
      centered
      closable={false}
      footer={[
        <ButtonCancel key="submit" onClick={handleCancel}>
          Закрыть
        </ButtonCancel>,
      ]}
    >
      {data?.error !== null ? (
        <Content>
          <Head>
            <Info />
            <Title>Запрос не принят</Title>
          </Head>
          <Divider />
          <Text>
            Произошла ошибка. Запрос на предоставление документа не сформирован.
            Пожалуйста повторите запрос позже.
          </Text>
        </Content>
      ) : (
        <Content>
          <Head>
            <Info />
            <Title>Запрос принят</Title>
          </Head>
          <Divider />
          <Text>
            Запрос на предоставление документа сформирован, более подробная
            информация будет предоставлена на электронный почтовый ящик.
          </Text>
        </Content>
      )}
    </Modal>
  );
}
