import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { YMaps, Map, ObjectManager } from "react-yandex-maps";
import srv from "../../../../services";
import { Wrapper, Zoom } from "./Map.styled";

interface Props {
  region: number;
  mapZoom: number;
  zoomIcon: React.ReactNode;
  onZoomClick?: () => void;
}

export default function _Map({
  region,
  mapZoom,
  zoomIcon,
  onZoomClick,
}: Props) {
  const { serviceCenters, mapCenter } = useServiceCenters(region);

  return (
    <Wrapper>
      {!!onZoomClick && <Zoom onClick={onZoomClick}>{zoomIcon}</Zoom>}

      <YMaps>
        <Map
          key={region}
          width="100%"
          height="100%"
          state={{
            center: mapCenter,
            zoom: serviceCenters.length ? mapZoom : 15,
          }}
        >
          <ObjectManager
            options={{
              clusterize: true,
            }}
            clusters={{
              preset: "islands#lightBlueClusterIcons",
            }}
            objects={{
              openBalloonOnClick: true,
              preset: "islands#lightBlueDotIcon",
            }}
            modules={[
              "objectManager.addon.objectsBalloon",
              "objectManager.addon.objectsHint",
            ]}
            features={{
              type: "FeatureCollection",
              features: serviceCenters.map((item, index) => ({
                id: index,
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: item.coordinates,
                },
                properties: {
                  balloonContent: `
                    <div class="map-baloon">
                      <h2>${item.address}</h2>
                      ${
                        item.phone
                          ? `<div><b>Телефон</b> ${item.phone}</div>`
                          : ""
                      }
                      ${
                        item.schedule
                          ? `<div><b>Режим работы</b> ${item.schedule}</div>`
                          : ""
                      }
                      ${
                        item.offdays
                          ? `<div><b>Выходные дни</b> ${item.offdays}</div>`
                          : ""
                      }
                    </div>
                  `,
                },
              })),
            }}
          />
        </Map>
      </YMaps>
    </Wrapper>
  );
}

function useServiceCenters(region: number) {
  const { data: serviceCentersRaw } = useQuery<Models.ServiceCenter[]>(
    ["serviceCenters", region],
    () => srv.getServiceCenters(region),
    { initialData: [] }
  );

  const serviceCenters = useMemo(() => {
    return serviceCentersRaw.filter((s) => s?.coordinates?.[0]);
  }, [serviceCentersRaw]);

  const mapCenter = useMemo(() => {
    if (!serviceCenters?.[0]?.coordinates?.[0]) return [57.16, 65.51];

    const sums = serviceCenters.reduce(
      (acc, { coordinates }) => {
        return [acc[0] + coordinates[0], acc[1] + coordinates[1]];
      },
      [0, 0]
    );

    return [
      sums[0] / serviceCenters.length + 0.2,
      sums[1] / serviceCenters.length,
    ];
  }, [serviceCenters]);

  return { serviceCenters, mapCenter };
}
