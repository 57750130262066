import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
`;

export const Title = styled.div`
  display: flex;
  width: 233px;
  flex-direction: column;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
`;

export const ContactTitle = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  margin-bottom: 18px;

  h2 {
    margin: 0;
  }
`;

export const Underline = styled.div`
  width: 100%;
  height: 3px;
  background: #A3A3A533;
`;

export const MainInfoBlock = styled.div`
  display: flex;
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;

export const MainData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const PhoneMail = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
`;
export const AddressInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div<{ kind?: string }>`
  flex: 1 1;
  margin: 0 0 30px 0;

  @media (min-width: 800px) {
    order: ${props => {
      switch (props.kind) {
        case 'phone': return 1;
        case 'inn': return 2;
        case 'address': return 3;
        case 'password': return 4;
        case 'email': return 5;
        case 'kpp': return 6;
        case 'remove': return 7;
        case 'ogrn': return 8;
        case 'address-mail': return 9;
        default: return 0;
      }
    }}
  }

  @media (min-width: 1160px) {
    flex: 0 0 calc(25% - 20px);
  }

  > * {
    display: block;
    margin-bottom: 2px;
    font-size: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    font-size: ${props => ['company', 'address'].includes(props.kind) ? '15px' : '17px'};
    font-weight: 500;
  }

  u {
    font-size: 15px;
    text-decoration: none;
    font-weight: 500;
    color: #A3A3A5;
  }

  // button {
  //   font-weight: 500;
  //   background: linear-gradient(
  //     180deg,
  //     #00aae0 39.06%,
  //     rgba(0, 158, 224, 0.32) 100%
  //   );
    button {
      font-weight: 500;
      background: linear-gradient(
        180deg,
        rgba(103,153,64,1) 39%,
        rgba(163,207,98,1) 100%
      );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    border: none;
    padding: 0;
    outline: 0;
    cursor: pointer;

    &:disabled {
      color: #ccc;
      cursor: default;
    }
  }

  .ant-spin {
    display: inline-block;
    margin: 5px 0 0 2px;
  }
`;
