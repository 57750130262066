import React, { useCallback, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { notification } from 'antd';
import TweenOne from 'rc-tween-one';
import SignIn from './SignIn';
import RegistrationConfirm from './RegistrationConfirm';
import RestorePassword from './RestorePassword';
import RestorePasswordConfirm from './RestorePasswordConfirm';
import EmailConfirm from '../SettingsData/Settings/EmailConfirm';
import {
  PhoneIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  NotificationIcon,
} from '../_assests';
import {
  Spin,
  Wrapper,
  Content,
  Header,
  Logo,
  Title,
  Phone,
  Auth,
  Tabs,
  Water,
  Manual,
  Footer,
  LogoBlock,
  PhoneBlock,
  Notification,
  DrawerBlock,
  DrawerButton,
} from './Home.styled';
import ChatwootWidget from '../_common/ChatwootWidget';

interface Props {
  isLoading?: boolean;
}

export default function Home({ isLoading = false }: Props) {
  const [activeNavItem, setActiveNavItem] = useState('signin');
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const navigation = [
    {
      key: 'signin',
      name: 'Вход',
      render: <SignIn onForgot={() => setActiveNavItem('restore')} />,
    },
    //{ key: 'register', name: 'Регистрация', render: <Registration /> },
    { key: 'restore', render: <RestorePassword /> },
  ];

  const slides = [
    {
      title: 'Без личного визита',
      description:
        'Получайте всю актуальную информацию в любое время без очередей',
    },
    {
      title: 'Удобно',
      description: 'Контролируйте состояние всех договоров в одном кабинете',
    },
    {
      title: 'Консультируйтесь онлайн',
      description:
        'Обращайтесь к специалистам и получайте ответы через форму обратной связи',
    },
  ];

  const { activeSlide, onSlideNext, onSlidePrev } = useSlider(slides.length);

  if (isLoading) return <Spin size="large" />;

  return (
    <Wrapper style={{ backgroundImage: `url(/slide${activeSlide + 1}.png)` }}>
      <Switch>
        <Route path="/register/:approveKey" component={RegistrationConfirm} />
        <Route
          path="/restore-password/:approveKey"
          component={RestorePasswordConfirm}
        />
        <Route
          path="/change-email/:approveKey"
          render={() => (
            <EmailConfirm
              showSpin={false}
              onSuccess={(message) => {
                notification.success({
                  message,
                  placement: 'bottomRight',
                  duration: 7,
                });
              }}
            />
          )}
        />
      </Switch>

      <Header>
        <LogoBlock>
          {/* <a href="https://yugra-ecology.ru/">
            <Logo
              src="/logo_vostok.png"
              style={{
                width: '120px',
                height: '120px',
                //marginBottom: '40px',
                marginTop: '-10px',
                marginLeft: '25px',
                //marginRight: '-100px',
              }}
              alt="АО «ЭК «Восток»"
            />
          </a> */}

          <Title style={{ marginLeft: '25px' }}>
            <a href="https://yugra-ecology.ru/">
              <h1>Личный кабинет</h1>
              <p style={{ color: '#679940' }}>для бизнеса</p>
            </a>
          </Title>
        </LogoBlock>

        <PhoneBlock>
          <Phone>
            <PhoneIcon
              style={{ color: 'red', fill: 'red', borderColor: 'red' }}
            />
            8 800 222 11 86
          </Phone>
          <Notification onClick={showDrawer}>
            <TweenOne
              animation={{
                scale: 0.8,
                yoyo: true,
                repeat: -1,
                duration: 800,
              }}
            >
              <NotificationIcon />
            </TweenOne>
          </Notification>
        </PhoneBlock>
      </Header>

      <Content>
        <Auth>
          <Tabs
            // centered
            animated={false}
            destroyInactiveTabPane
            tabBarGutter={70}
            activeKey={activeNavItem}
            onChange={setActiveNavItem}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            {navigation.map((navItem) => (
              <Tabs.TabPane
                key={navItem.key}
                tab={navItem.name}
                disabled={!navItem.name}
              >
                {navItem.render}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Auth>

        <Water>
          <article>
            <i>{activeSlide + 1}</i>
            <h2>{slides[activeSlide].title}</h2>
            <p>{slides[activeSlide].description}</p>
          </article>

          <span>
            <ArrowLeftIcon onClick={onSlidePrev} />
            <ArrowRightIcon onClick={onSlideNext} />
          </span>

          <Manual
            fileName="Инструкция пользователя ЛК ЮЛ.pdf"
            url={`${window.location.origin}/manual.pdf`}
            loading={false}
            style={{ color: 'white' }}
          >
            Инструкция пользователя
          </Manual>
        </Water>
      </Content>

      <Footer />
      <DrawerBlock
        title="Уважаемые клиенты!"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={500}
        footer={<DrawerButton onClick={onClose}>Закрыть</DrawerButton>}
      >
        <div>
          <p>
            Предлагаем вам воспользоваться новым дистанционным сервисом – Личным
            кабинетом для юридических лиц, индивидуальных предпринимателей и
            физических лиц – владельцев нежилых помещений.
          </p>
          <ol>
            Личный кабинет позволяет:
            <li>
              {' '}
              • контролировать договоры, заключенные между вами и поставщиками
              услуг и ресурсов, для которых наша компания выполняет агентские
              функции;
            </li>
            <li>
              • дистанционно подать официальное обращение с официальным
              присвоением входящего номера и выбрать удобный способ получения
              ответа.
            </li>
          </ol>
          <ol>
            Функционал сервиса будет учитывать пожелания клиентов и существенно
            расширяться. В настоящее время в разработке находятся:
            <li> • возможность передачи показаний приборов учета; </li>
            <li>
              • детализация начислений за оказанные услуги по договорам с
              поставщиками;{' '}
            </li>
            <li>
              {' '}
              • прямая связь со специалистами, ответственными за договоры;
            </li>
            <li>
              {' '}
              • отслеживание статусов обращений от момента регистрации до
              получения ответа и сроки их обработки;
            </li>
            <li> • просмотр истории обращений клиента. </li>
          </ol>
          <p>
            Доступ автоматически получают все клиенты, которые в договорах с
            поставщиками ресурсов указали мобильный телефон.
          </p>
          <p>
            Если данных в вашем договоре нет, то вам необходимо обратиться к нам
            лично или через Почту России с официальным письмом от
            уполномоченного лица о предоставлении телефона и подключении услуги
            «Личный кабинет».
          </p>
          <b>
            Цените свое время и здоровье, используйте наши дистанционные
            сервисы!
          </b>
        </div>
      </DrawerBlock>
      {/* <ChatwootWidget /> */}
    </Wrapper>
  );
}

function useSlider(count: number) {
  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideNext = useCallback(() => {
    if (activeSlide < count - 1) {
      setActiveSlide(activeSlide + 1);
    } else {
      setActiveSlide(0);
    }
  }, [count, activeSlide]);

  const onSlidePrev = useCallback(() => {
    if (activeSlide === 0) {
      setActiveSlide(count - 1);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  }, [count, activeSlide]);

  return { activeSlide, onSlideNext, onSlidePrev };
}
