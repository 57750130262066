import React from "react";
import { Alert, Button } from "antd";
import { Wrapper } from "./ConfirmSuccess.styled";

interface Props {
  message: string;
}

export default function ConfirmSuccess({ message, ...restProps }: Props) {
  return (
    <Wrapper {...restProps}>
      <Alert type="success" message={message} />

      <Button type="primary" onClick={() => window.location.reload()}>
        Вернуться на главную страницу
      </Button>
    </Wrapper>
  );
}
