import styled from 'styled-components';

export const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 30px 10px 0;

  @media(min-width: 480px) {
    justify-content: flex-start;
    padding-left: 90px;
    padding-right: 90px;
  }
`;

export const NavItem = styled.div<{ active?: boolean }>`
  position: relative;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0;
  cursor: pointer;

  &:after {
    display: ${props => props.active ? 'block' : 'none'};
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: ${props => props.theme.blue};
    border-radius: 5px;
  }
`;

export const MapWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;

  > div {
    flex: 1;
  }
`;
