import axios from 'axios';

export async function getContragent(): Promise<Models.Contragent> {
  const res = await axios.get('contragent');
  return res.data;
}

export async function setSelectContragent(query) {
  const res = await axios.post(`contragent?defaultUid=${query}`);
  return res.statusText;
}

export async function getContragentList(): Promise<Models.ContragentList[]> {
  const res = await axios.get('contragent/list');
  return res.data;
}

export async function getContracts(contragentId): Promise<Models.Contract[]> {
  if (!contragentId) return null;
  const res = await axios.get(`contract/list/${contragentId}`);
  return res.data;
}

export async function getNotificationNotReadCount(payload): Promise<any> {
  if (!payload) return null;
  const res = await axios.post(
    `notification/GetNotificationNotReadCount`,
    payload
  );
  return res.data;
}

export async function setNotificationReadStatus(payload): Promise<any> {
  if (!payload) return null;
  const res = await axios.post(
    `notification/SetNotificationReadStatus`,
    payload
  );
  return res;
}

export async function getNotificationList(payload): Promise<any> {
  if (!payload) return null;
  const res = await axios.post(`notification/GetNotificationList`, payload);
  return res.data;
}

export async function getConnections(contractId): Promise<Models.Connection[]> {
  if (!contractId) return null;
  const res = await axios.get(`connection/list/${contractId}`);
  return res.data;
}

export async function getAccountingData(
  contractId
): Promise<Models.Connection[]> {
  if (!contractId) return null;
  const res = await axios.get(`accountingpoint/list/${contractId}`);
  return res.data;
}

export async function getMeteringDevice(
  connectionId
): Promise<Models.MeteringDevices[]> {
  if (!connectionId) return null;
  const res = await axios.get(`meteringdevice/list/${connectionId}`);
  return res.data;
}

export async function getMeteringDeviceUnique(
  connectionId
): Promise<Models.MeteringDevices[]> {
  if (!connectionId) return null;
  const res = await axios.get(`meteringdevice/listunique/${connectionId}`);
  return res.data;
}

export async function checkVolumeMetering(payload) {
  const res = await axios.post(`meteringdevice/checkcountervalues`, payload);
  return res.data;
}

export async function sendVolumeMetering(payload) {
  const res = await axios.post(`meteringdevice/sendcountervalues`, payload);
  return res;
}

export async function getMeteringAccruals(payload) {
  const res = await axios.post(`meteringdevice/getcounterhistory`, payload);
  return res.data;
}

export async function getDebts(payload) {
  const res = await axios.post(`contract/GetDebts`, payload);
  return res.data;
}

export async function requestDocuments(payload) {
  const res = await axios.post(`contract/SendWorkDoc2AIS`, payload);
  return res.data;
}

export async function downloadDocConnection(data) {
  const res = await axios.post('soDocs/getSoDocs', data);

  const MAIN_DOC_NAME = {
    1: 'Проект договора энергоснабжения',
    2: 'Дополнительное соглашение к договору энергоснабжения',
    3: 'Акт о технологическом присоединении',
    4: 'Акт допуска прибора учёта в эксплуатацию',
  };

  const APP_DOC_NAME = 'Приложение к договору';
  const SIG_DOC_NAME = 'Электронная цифровая подпись';

  //let isSigDownloaded = false;

  if (res && res.data && res.data.docs && res.data.docs.length > 0) {
    res.data.docs.map((item) => {
      // Основной документ
      const linkMain = document.createElement('a');
      linkMain.href = `data:application/pdf;base64,${item.digitalDocs}`;
      linkMain.download = MAIN_DOC_NAME[item.docsType];
      linkMain.click();

      // Приложения
      if (item.digitalApps) {
        item.digitalApps.map((value: string, index: number) => {
          const linkApp = document.createElement('a');
          linkApp.href = `data:application/pdf;base64,${value}`;
          linkApp.download = `${APP_DOC_NAME}_${index + 1}`;
          linkApp.click();
        });
      }

      // Цифровая подпись
      // Включили множество подписей (ЛКЮЛ-00000000274)
      // if (item.digitalSignature && !isSigDownloaded) {
      if (item.digitalSignature) {
        item.digitalSignature.map((value: string, index: number) => {
          const linkSig = document.createElement('a');
          linkSig.href = `data:text/plain;base64,${value}`;
          linkSig.download = `${SIG_DOC_NAME}_${index + 1}.sig`;
          //isSigDownloaded = true;
          linkSig.click();
        });
      }
    });
  } else {
    return res.data;
  }
}
