import React from "react";
import { Switch, Route } from "react-router-dom";
import Settings from "./Settings";
import { ContentNav } from "../_common";

export default function SettingsData() {
  return (
    <>
      <ContentNav
        links={[{ name: "Профиль", path: "/settings", exact: true }]}
      />
      <Switch>
        <Route component={Settings} />
      </Switch>
    </>
  );
}
