import styled from 'styled-components';
import * as antd from 'antd';

export const SelectBlock = styled.div`
  margin-bottom: 20px;

  .ant-select-selector{
    border-color: rgb(103, 153, 64) !important
  }
      
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }

  .ant-input{
    border-color: rgb(103, 153, 64)
  }
  .ant-input:focus{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }
  .ant-select-arrow svg {
    fill: rgb(103, 153, 64);
}
`;

export const PaymentButton = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  color: #313149;
  display: block;
  margin-left: auto;
  margin-top: 44px;
  width: 300px;
  font-size: 18px;
  line-height: 18px;
`;
