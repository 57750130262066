import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useQuery, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru';
import localeRU from 'antd/lib/locale-provider/ru_RU';
import {
  FeedbackIcon,
  ProfileIcon,
  LocationNavIcon,
  Frame,
  Broadcast,
  PaymentIcon,
} from './_assests';
import queryClient from '../queryClient';
import srv from '../services';
import {
  Home,
  Feedback,
  Profile,
  SettingsData,
  OfficeMap,
  BroadCast,
  Payment,
} from '.';
import { Layout, Styles, theme } from './_common';
import 'antd/dist/antd.css';
import 'slick-carousel/slick/slick.css';

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={localeRU}>
        <ThemeProvider theme={theme}>
          <Router>
            <Styles />
            <Main />
          </Router>
        </ThemeProvider>
      </ConfigProvider>

      <ReactQueryDevtools
        position="bottom-right"
        toggleButtonProps={{ style: { filter: 'opacity(0.01)' } }}
      />
    </QueryClientProvider>
  );
}

function Main() {
  const { data: user, ...userQ } = useQuery('user', srv.getCurrentUser, {
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: contragent, ...contragentQ } = useQuery(
    'contragent',
    srv.getContragent,
    { enabled: !!user?.uid }
  );

  const contractsQ = useQuery(
    'contracts',
    () => srv.getContracts(contragent?.id),
    { initialData: [], enabled: !!contragent }
  );

  useQuery(
    'notifications',
    () => srv.getNotificationList({ ContragentId: contragent?.id }),
    {
      initialData: [],
      enabled: !!contragent?.id,
    }
  );

  useQuery(
    'notificationNotReadCount',
    () => srv.getNotificationNotReadCount({ ContragentId: contragent?.id }),
    {
      initialData: [],
      enabled: !!contragent?.id,
    }
  );

  useQuery('contragentList', srv.getContragentList, {
    initialData: [],
    enabled: !!user,
  });

  const { data: feature } = useQuery<Models.Feature>(
    'feature',
    srv.getFeature,
    { initialData: {}, enabled: !!user }
  );
  useQuery('date', srv.getServerDate, { initialData: moment().toJSON() });
  useQuery('answerTypes', srv.getAnswerTypes, {
    initialData: [],
    enabled: !!user,
  });
  useQuery('services', srv.getServices, { initialData: [], enabled: !!user });
  useQuery('territories', srv.getTerritories, {
    initialData: [],
    enabled: !!user,
  });

  useQuery('documents', srv.getWorkDocTypes, {
    initialData: [],
    enabled: !!user,
  });

  useQuery('topics', srv.getTopics, { initialData: [], enabled: !!user });

  if (!user) return <Home isLoading={userQ.isLoading} />;

  return (
    <>
      <Layout
        isLoading={contragentQ.isLoading || contractsQ.isLoading}
        error={contragentQ.error || contractsQ.error}
        navigation={[
          {
            url: '/contracts',
            name: 'Главная',
            icon: <ProfileIcon />,
            component: Profile,
          },
          {
            url: '/payment',
            name: 'Оплата',
            icon: <PaymentIcon />,
            component: Payment,
          },
          {
            url: '/feedback',
            name: 'Поддержка клиентов',
            icon: <FeedbackIcon />,
            component: Feedback,
            featured: feature?.totalCount > 0,
          },
          // {
          //   url: '/broadcast',
          //   name: 'Передача показаний',
          //   icon: <Broadcast />,
          //   component: BroadCast,
          // },
          {
            url: '/map',
            name: 'Офисы обслуживания',
            icon: <LocationNavIcon />,
            component: OfficeMap,
          },
          {
            url: '/settings',
            name: 'Настройки',
            icon: <Frame />,
            component: SettingsData,
          },
        ]}
      />
    </>
  );
}
