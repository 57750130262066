import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`
  .ant-modal {
    top: 1rem;
    max-width: 100%;

    &-mask {
      z-index: 1021;
    }

    &-wrap {
      z-index: 1022;
    }

    &-body {
      padding: 1.9rem 2rem;
      min-height: 2.5rem;
    }

    &-close {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 0.3rem;
      margin: 0.79rem 0.77rem;

      &-x {
        width: 1.7rem;
        height: 1.7rem;
        line-height: 2rem;

        svg {
          width: 100%;
          height: 100%;
          fill: ${props => props.theme.blueSaturated};
        }

        &:hover svg {
          fill: ${props => props.theme.orange};
        }
      }

      &:focus {
        outline: 0;
      }
    }

    footer {
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;
    }
  .ant-modal-footer {
    button {
      display: flex;
      margin: auto;
      align-items: center;
      border-width: 3px;
      border-radius: 90px;
      font-size: 20px;
      line-height: 30px;
      height: 42px;
    }
}
`;
