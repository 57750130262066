import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .ant-btn {
    display: block;
    margin-top: 1.23rem;
  }
`;
