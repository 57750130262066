import axios from "axios";

export async function getCurrentUser(): Promise<Models.User> {
  const res = await axios.get("user");
  return res.data;
}

export async function authUser(payload): Promise<Models.User> {
  const res = await axios.post("identity/auth", payload);
  if (res.data?.accessToken) {
    localStorage.setItem("token", res.data.accessToken);
  }
  const user = await getCurrentUser();
  return user;
}

export async function regUser(payload) {
  const res = await axios.post("identity/register", payload);
  return res.statusText;
}

export async function regUserApproved(payload) {
  const res = await axios.post("identity/register/approved", payload);
  return res.statusText;
}

export async function regUserConfirm(approveKey) {
  const res = await axios.post("identity/approve", { approveKey });
  return res.statusText;
}

export async function regUserPhoneConfirm(payload) {
  const res = await axios.post("identity/approve", payload);
  return res.statusText;
}

export async function regUserResend(login) {
  const res = await axios.post("identity/register/resend", { login });
  return res.statusText;
}

export async function changeUserPassword(payload) {
  const res = await axios.post("identity/change/password", payload);
  return res.statusText;
}

export async function changeUserEmail(payload) {
  const res = await axios.post("identity/change/email", payload);
  return res.statusText;
}

export async function changeUserEmailConfirm(approveKey) {
  const res = await axios.post("identity/approve", { approveKey });
  return res.statusText;
}

export async function changeUserPhone(payload) {
  const res = await axios.post("identity/change/phone", payload);
  return res.statusText;
}

export async function changeUserPhoneConfirm(approveKey) {
  const res = await axios.post("identity/approve", { approveKey });
  return res.statusText;
}

export async function restoreUser(payload) {
  const res = await axios.post("identity/restore", payload);
  return res.statusText;
}

export async function restoreUserConfirm({ approveKey, password }) {
  const res = await axios.post("identity/approve", {
    approveKey,
    value: password,
  });
  return res.statusText;
}

export async function setUserUid(uid) {
  const res = await axios.post("uid", { uid });
  return res.statusText;
}

export async function getUidInfo(uid): Promise<Models.Contragent> {
  const res = await axios.get(`uid/${uid}`);
  return res.data;
}

export async function removeUserUid(uid) {
  const res = await axios.delete(`uid/${uid}`);
  return res.statusText;
}

export async function getUserMethod() {
  const res = await axios.get("/identity/change/getisatolphone");
  return res;
}

export async function saveUserMethod(payload) {
  const res = await axios.post("/identity/change/isatolphone", payload);
  return res.statusText;
}
