import React, { useCallback, useState, useRef, useEffect } from 'react';
import axios, { Canceler } from 'axios';
import download from 'downloadjs';
import { Button, notification } from 'antd';
import { ButtonProps } from 'antd/lib/button';

interface Props {
  url: string;
  fileName: string;
}

export default function DownloadButton({ children, ...restProps }: Props & ButtonProps) {
  const { isFileDownloading, onDownloadFile } = useFile(restProps);

  return (
    <Button
      size="small"
      type="link"
      loading={isFileDownloading}
      onClick={onDownloadFile}
      {...restProps}
    >
      {children}
    </Button>
  );
}

function useFile({ url, fileName }: Props) {
  const [isFileDownloading, setFileDownloading] = useState(false);

  const cancelRequest = useRef<Canceler | null>(null);

  const onDownloadFile = useCallback(async () => {
    setFileDownloading(true);

    try {
      const { data } = await axios.get(url, {
        responseType: 'blob',
        cancelToken: new axios.CancelToken(canceler => {
          cancelRequest.current = canceler;
        }),
      });
      download(data, fileName);
    } catch ({ response }) {
      if (response?.data === undefined) return;
      const reader = new FileReader();
      reader.readAsText(response.data);
      reader.addEventListener('loadend', (e: any) => {
        const message = e.srcElement?.result || 'Произошла ошибка при загрузке файла';

        notification.error({
          message,
          description: fileName,
        });
      });
    }

    setFileDownloading(false);
  }, [url, fileName]);

  useEffect(() => () => {
    if (cancelRequest.current) cancelRequest.current();
  }, []);

  return { isFileDownloading, onDownloadFile };
}
