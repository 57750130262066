import React, { useCallback } from "react";
import { useMutation } from "react-query";
import { Form, Input, Alert } from "antd";
import srv from "../../../services";
import { BaseForm, Title, SubmitButton } from "../Forms.styled";
import RestorePasswordConfirm from "../RestorePasswordConfirm";

export default function RestorePassword() {
  const { form, isLoading, isSuccess, message, error, ...formProps } =
    useResetPassword();

  if (isSuccess && !form.getFieldValue("login").includes("@"))
    return <RestorePasswordConfirm byPhone />;

  if (isSuccess) return <Alert type="success" message={message} />;

  return (
    <BaseForm form={form} layout="vertical" size="large" {...formProps}>
      <Title>Для восстановления аккаунта введите Ваш телефон или e-mail</Title>

      <Form.Item
        name="login"
        label="Телефон или E-mail"
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <SubmitButton htmlType="submit" size="large" loading={isLoading}>
        Восстановить пароль
      </SubmitButton>
    </BaseForm>
  );
}

function useResetPassword() {
  const [form] = Form.useForm();

  const {
    mutate: restore,
    isLoading,
    isSuccess,
    error,
    data: message,
    reset,
  } = useMutation(srv.restoreUser);

  const onFinish = useCallback(
    (values) => {
      restore(values);
    },
    [restore]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    form,
    isLoading,
    isSuccess,
    message,
    error,
    onValuesChange,
    onFinish,
  };
}
