import React from 'react';
import { useQuery } from 'react-query';
import srv from '../../../../services';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../_assests';
import { Spin, Slider } from './Connections.styled';
import { makeStyles } from 'tss-react/mui';

interface Props {
  contractId?: number;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
    borderRadius: '5px',
    width: 'auto !important',
    overflow: 'auto',
    marginBottom: '20px',
    backgroundColor: 'white',
    padding: '20px',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '510px',
    width: '920px !important',
    borderRadius: '5px',
    paddingLeft: '-20px',
    paddingTop: '-10px',
    overflow: 'auto',
    marginBottom: '20px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemSecond: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  addItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginRight: '20px',
    width: 'auto !important',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px red solid',
  },
  label: {
    fontSize: '12px',
    color: '#a3a3a5',
    textWrap: 'balance',
  },
  labelText: {
    fontSize: '14px',
    marginBottom: '5px',
    textWrap: 'balance',
  },
  labelSecond: {
    fontSize: '12px',
    color: '#a3a3a5',
    textWrap: 'balance',
  },
  labelTextSecond: {
    fontSize: '14px',
    marginBottom: '10px',
    width: '200px',
    textWrap: 'balance',
  },
  secondBlockDevider: {
    marginRight: '40px',
  },
}));

export default function Connections({ contractId }: Props) {
  const { classes } = useStyles();

  const { data: connections, isLoading } = useQuery(
    ['connections', contractId],
    () => srv.getConnections(contractId)
  );

  if (isLoading) return <Spin />;

  if (!connections?.length) return <>Нет подключений</>;

  return (
    <Slider
      infinite={false}
      dots
      speed={500}
      prevArrow={<ArrowLeftIcon />}
      nextArrow={<ArrowRightIcon />}
    >
      <div className={classes.container}>
        {connections.map((connection, index) => {
          return (
            <React.Fragment>
              <div className={classes.wrapper}>
                <div className={index > 0 ? classes.itemSecond : classes.item}>
                  <div className={classes.label}>Объект</div>
                  <div className={classes.labelText}>{connection.name}</div>
                  <div className={classes.label}>Состояние</div>
                  <div className={classes.labelText}>
                    {connection.buildingState}
                  </div>
                  <div className={classes.label}>Тип строения</div>
                  <div className={classes.labelText}>
                    {connection.buildingType}
                  </div>
                  <div className={classes.label}>Адрес</div>
                  <div className={classes.labelText}>{connection.address}</div>

                  <div className={classes.addItem}>
                    {connection?.accountingPointInfo.map((accountingPoint) => {
                      return (
                        <React.Fragment>
                          <div className={classes.secondBlockDevider}>
                            <div className={classes.labelSecond}>
                              Наименование точки учета
                            </div>
                            <div className={classes.labelTextSecond}>
                              {accountingPoint.pointName}
                            </div>
                            <div className={classes.labelSecond}>
                              Номенклатура
                            </div>
                            <div className={classes.labelTextSecond}>
                              {accountingPoint.nomenclatureName}
                            </div>
                            <div className={classes.labelSecond}>
                              Текущий тариф
                            </div>
                            <div className={classes.labelTextSecond}>
                              {accountingPoint.feeActual}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </Slider>
  );
}
