import axios, { AxiosError } from 'axios';
import queryClient from '../queryClient';
import * as contragent from './contragent';
import * as dict from './dict';
import * as feature from './feature';
import * as feedback from './feedback';
import * as serviceCenters from './serviceCenters';
import * as system from './system';
import * as user from './user';
import * as phone from './phone';
import * as payment from './payment';
import { homepage } from '../../package.json';

// Определить куда направить.
axios.defaults.baseURL = window.location.href.startsWith(homepage)
  ? `${homepage}/api/back/v1`
  : 'https://ulk.yugra-ecology.ru/api/back/v1';

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (res) => {
    if (res.config.responseType === 'blob') return res;

    if (res.data?.status === 'error') {
      const errorArray = [
        100210, 100212, 100213, 100214, 100215, 100216, 100217, 100218, 100219,
        100220,
      ];
      if (errorArray.includes(res.data?.errors?.[0]?.code)) {
        return Promise.reject(res.data?.errors?.[0]?.code?.toString());
      }
      return Promise.reject(
        res.data?.errors?.[0]?.error || 'Произошла неизвестная ошибка'
      );
    }

    return {
      ...res,
      data: res.data?.response ?? null,
      statusText: res.data?.messages?.[0] ?? '',
    };
  },
  (err: AxiosError) => {
    const token = localStorage.getItem('token');

    if (err.response?.status === 401 && !!token) {
      localStorage.removeItem('token');
      queryClient.resetQueries('user');
      queryClient.clear();
    }

    return Promise.reject(err.message);
  }
);

const srv = {
  ...contragent,
  ...dict,
  ...feature,
  ...feedback,
  ...serviceCenters,
  ...system,
  ...user,
  ...phone,
  ...payment,
};

export default srv;
