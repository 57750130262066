import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;
  font-size: 12px;
  line-height: 14px;
  min-height: 50px;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center !important;
    justify-content: space-evenly;
    min-height: 160px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 0;
    padding: 10px!important;
  }

  @media(max-width: 768px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    justify-content: space-evenly;
    padding: 10px!important;
  }

  @media(max-width: 1024px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    justify-content: space-evenly;
    padding: 10px!important;
  }

  @media(max-width: 1899px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    justify-content: space-evenly;
    padding: 10px!important;
  }

  @media(min-width: 1900px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 40px 60px!important;
  }
`;

export const Social = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 15px;
  }
`;

export const Copyright = styled.a``;

export const Privacy = styled.a`
  margin-left: 43px;

  @media (max-width: 576px) {
    display: none;
  }

  @media(min-width: 990px) {
    margin-left: 0;
  }
  
`;

export const Address = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    margin-right: 9px;
    fill: ${props => props.theme.blue};
  }

  @media (max-width: 576px) {
    display: none;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Contacts = styled.div`
  display: flex;
  margin-right: 120px;

  > div {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
  
`;

export const Contact = styled.div`
  display: flex;

  svg {
    fill: #5492F2;
    height: 30px;
  }

  a {
    display: flex;
    align-items: center;

    &:hover svg {
      fill: ${props => props.theme.orange};
    }
  }

  span {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-left: 10px;
    margin-right: 7px;

    i {
      color: transparent;
    }
  }

  b {
    display: block;
    font-size: 24px;
    line-height: 36px;
    padding-left: 11px;
    padding-right: 15px;
  }

  @media (max-width: 576px) {
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
  }
  @media (max-width: 1440px) {
    margin-top: 0;
  }
`;

export const Phone = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    svg {
      display: none;
      fill: #5492f2;
      margin-right: 16px;
    }
  }
`;

