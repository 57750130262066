import React, { useState, useEffect } from "react";
import { TablePay, InputPay } from "./TableMutualSettlements.styled";
import moment from "moment";
import { Form } from "antd";
import SumPay from "../SumPay";
import _ from "lodash";
import { FormInstance } from "antd/lib/form";
import { notification, Select } from "antd";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

interface Props {
  isSubmiting?: boolean;
  isLoadingTable?: boolean;
  data?: Models.SelectedRowsPay[];
  inputSum: number;
  setInputSum: (val) => void;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (val) => void;
  selectedRows: any[];
  setSelectedRows: (val) => void;
}

export default function TableMutualSettlements({
  data,
  isSubmiting,
  isLoadingTable,
  inputSum,
  setInputSum,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedRows,
  setSelectedRows,
}: Props) {
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCopy, setDataSourceCopy] = useState([]);

  const formRef = React.useRef<FormInstance>(null);

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState<AlertColor>("error");
  const [selectionCounter, setSelectionCounter] = useState(5);

  useEffect(() => {
    formRef.current?.resetFields();
  }, []);

  useEffect(() => {
    setDataSource(data);
    if (data && data.length) {
      setDataSourceCopy(_.cloneDeep(data));
    }
  }, [data]);

  const columns = [
    {
      id: 1,
      title: "Вид документа",
      dataIndex: ["documentType"],
    },
    {
      id: 2,
      title: "Дата документа",
      dataIndex: ["documentDate"],
      render: (_, record) => {
        return moment(record.documentDate).format("DD-MM-YYYY");
      },
    },
    {
      id: 3,
      title: "Номер документа",
      dataIndex: ["documentNumber"],
    },
    {
      id: 4,
      title: "Период долга",
      dataIndex: ["periodDebt"],
    },
    {
      id: 5,
      title: "Вид долга",
      dataIndex: ["debtType"],
    },
    {
      id: 6,
      title: "Продавец",
      dataIndex: ["contragentSeller"],
    },
    {
      id: 7,
      title: "Сумма задолженности, ₽",
      dataIndex: ["monetaryAmount"],
    },
    {
      id: 8,
      title: "Сумма оплаты, ₽",
      dataIndex: ["paymentAmount"],
      editable: true,
      render: (_, record, index) => {
        return (
          <Form ref={formRef} name="control-ref" style={{ maxWidth: 600 }}>
            <Form.Item
              name={`amount-${index}`}
              rules={[{ required: false }]}
              initialValue={record?.monetaryAmount}
            >
              <InputPay
                formatter={(value) => value.replace(/(\.\d{2}).+/g, "$1")}
                parser={(value) => value.replace(",", ".")}
                size="small"
                value={inputSum}
                onChange={(e) => handleOnChange(e, record)}
              />
            </Form.Item>
          </Form>
        );
      },
    },
  ];

  const handleOnChange = (e, record) => {
    if (!e || e < 0) {
      e = 0;
    }

    // const recordSelected = selectedRows.filter(
    //   (x) => x.fileID === record.fileID
    // );

    // if (recordSelected && recordSelected.length && (e === 0 || e === "")) {
    //   setIsError(true);

    //   notification.error({
    //     message: "Сумма не может быть 0 или пустой",
    //     placement: "bottomRight",
    //     duration: 3,
    //   });
    // } else {
    //   setIsError(false);
    // }

    const foundRecord = dataSourceCopy.filter(
      (i) => i.fileID === record.fileID
    );

    const foundRecordOriginal = dataSource.filter(
      (i) => i.fileID === record.fileID
    );

    if (foundRecord && foundRecord.length) {
      foundRecord[0].monetaryAmount = e;
      foundRecordOriginal[0].updatedAmount = e;

      const selected = selectedRows.filter(
        (x: any) => x.fileID === foundRecord[0].fileID
      );

      if (selected && selected.length) {
        selected[0].updatedAmount = e;
      }
    }

    totalSumCalculator();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], record) => {
    if (
      newSelectedRowKeys &&
      Array.isArray(newSelectedRowKeys) &&
      newSelectedRowKeys.length > selectionCounter
    ) {
      return;
    }

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(record);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (record: any, selected: boolean) => {
      if (selected) {
        if (selectedRowKeys && selectedRowKeys.length === selectionCounter) {
          setSnackBarMessage(
            "Нельзя выделить более " + selectionCounter + " записей"
          );
          setSnackBarType("success");
          setSnackBarOpen(true);

          return;
        }

        const updatedRecords = [...selectedRowKeys];
        updatedRecords.push(record.fileID);
        setSelectedRowKeys(updatedRecords);
      } else {
        const filteredRecord = selectedRowKeys.filter(
          (x) => x !== record.fileID
        );
        if (filteredRecord && filteredRecord.length > 0) {
          setSelectedRowKeys(filteredRecord);
        } else {
          setSelectedRowKeys([]);
        }
      }
      if (selectedRowKeys && selectedRowKeys.length === 1 && !selected) {
        setInputSum(0);
      }
    },
    onSelectAll: (selected: boolean) => {
      if (selectedRowKeys && !selected) {
        setInputSum(0);
      }
    },
  };

  useEffect(() => {
    totalSumCalculator();
  }, [selectedRowKeys, inputSum]);

  const totalSumCalculator = () => {
    let totalSum = 0;
    dataSourceCopy.map((i) => {
      const filteredRecord = selectedRowKeys.filter((x) => x === i.fileID);
      if (filteredRecord && filteredRecord.length) {
        totalSum = totalSum + parseFloat(i.monetaryAmount);
        setInputSum(totalSum);
      }
    });
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBarType}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <TablePay
        rowKey={(record) => record.fileID}
        columns={columns}
        rowSelection={rowSelection}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        pagination={false}
        loading={isLoadingTable}
        style={{ marginTop: "20px" }}
      />
      {data && <SumPay isSubmiting={isSubmiting} inputSum={inputSum} />}
    </>
  );
}
