import React, { useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Alert, Form, Input } from "antd";
import { FormInstance, RuleObject } from "antd/lib/form";
import srv from "../../../../services";
import {
  SubmitButton,
  BaseForm,
  BackButton,
  Description,
} from "../../Forms.styled";

interface Props {
  phone: string;
  onBack: () => void;
}

export default function PasswordSettings({ phone, onBack }: Props) {
  const { isSubmiting, error, validatePasswordConfirm, ...formProps } =
    useForm(phone);

  return (
    <BaseForm layout="vertical" {...formProps}>
      <Description>
        <h4>Для входа в систему задайте пароль</h4>
        <p>
          12 символов. Цифры, заглавные и прописные - латинские и русские буквы.
          Символы–математические, обозначения денежных единиц, авторского права
          и товарного знака
        </p>
      </Description>
      <Form.Item name="phone" hidden>
        <Input defaultValue={phone} />
      </Form.Item>
      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="passwordConfirm"
        label="Повторите пароль"
        dependencies={["password"]}
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <BackButton size="large" onClick={onBack}>
        Назад
      </BackButton>

      <SubmitButton htmlType="submit" size="large" loading={isSubmiting}>
        Подтвердить
      </SubmitButton>
    </BaseForm>
  );
}

function useForm(phone: string) {
  const queryClient = useQueryClient();
  const authenticate = useMutation(srv.authUser, {
    onSuccess: (data) => queryClient.setQueryData<any>("user", data),
  });
  const register = useMutation(srv.regUserApproved, {
    onSuccess: (data, values) =>
      authenticate.mutate({ login: phone, password: values.password }),
  });

  const validatePasswordConfirm = useCallback(
    ({ getFieldValue }: FormInstance): RuleObject => ({
      validator(_, value) {
        if (!value || value === getFieldValue("password")) {
          return Promise.resolve();
        }
        return Promise.reject("Пароль и подтверждение не совпадают");
      },
    }),
    []
  );

  const onFinish = useCallback(
    (values) => {
      register.mutate({ ...values, phone });
    },
    [register, phone]
  );
  const onValuesChange = useCallback(() => {
    if (register.error) register.reset();
  }, [register]);

  useEffect(() => {
    if (register.isSuccess) {
      //onSuccess(message);
      register.reset();
    }
  }, [register]); //, onSuccess]);

  return {
    isSubmiting: register.isLoading,
    error: register.error,
    validatePasswordConfirm,
    onValuesChange,
    onFinish,
  };
}
