import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  min-height: 300px;

  > div {
    flex: 1;
  }

  .map-baloon {
    > * {
      line-height: 1.33em;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      font-weight: 300;
      font-size: 1.2em;
    }

    b {
      display: block;
    }
  }
`;

export const Zoom = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: #ffeec7;
  }
`;
