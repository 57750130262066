import styled from 'styled-components';
import * as antd from 'antd';
import * as forms from '../Forms.styled';
import _ConfirmSuccess from '../ConfirmSuccess';

export const BaseForm = styled(forms.BaseForm)`
  h2 {
    margin-top: 1.5rem;
    text-align: center;
  }
`;

export const ConfirmSuccess = styled(_ConfirmSuccess)`
  margin: 2rem auto;
  max-width: 23rem;
`;

export const Alert = styled(antd.Alert)`
  margin-bottom: 1rem!important;
`;
