import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { CloseIcon } from '../../_assests';
import { Styles } from './Modal.styled';

interface Props {
  children: React.ReactNode;
}

export default function _Modal(props?: Props & Partial<ModalProps>) {
  return (
    <>
      <Modal
        destroyOnClose
        footer={null}
        maskClosable={false}
        closeIcon={<CloseIcon />}
        {...props}
      />

      <Styles />
    </>
  );
}
