import { Nav, NavLink } from "./ContentNav.styled";
import { Badge } from "antd";

interface Props {
  links: {
    name: string;
    path: string;
    exact?: boolean;
    featured?: number;
    note?: number;
  }[];
}

export default function ContentNav({ links, ...restProps }: Props) {
  return (
    <Nav {...restProps}>
      {links.map((link) => (
        <NavLink key={link.path} to={link.path} exact={link.exact || false}>
          {!!link.featured && <b>{link.featured}</b>}

          <u>
            {link.name}{" "}
            {link.note ? <Badge count={link.note} offset={[5, -20]} /> : ""}
          </u>
        </NavLink>
      ))}
    </Nav>
  );
}
