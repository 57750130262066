import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      ...(
        process.env?.NODE_ENV === 'development' ? {
          retry: 0,
          refetchOnWindowFocus: false,
        } : {}
      ),
    },
  },
});

export default queryClient;
