import React from "react";
import { SumPayBlock, PaymentButton, Description } from "./SumPay.styled";

interface Props {
  isSubmiting?: boolean;
  inputSum?: number;
  onFinish?: any;
}
const SumPay = ({ isSubmiting, inputSum }: Props) => {
  return (
    <SumPayBlock>
      <Description>
        Итого к оплате: <b>{inputSum ? `${inputSum.toFixed(2)}₽` : `0₽`}</b>
      </Description>
      <PaymentButton
        htmlType="submit"
        loading={isSubmiting}
        disabled={inputSum ? false : true}
      >
        Оплатить
      </PaymentButton>
    </SumPayBlock>
  );
};

export default SumPay;
