import React, { useState } from 'react';
import { Select, Button } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import srv from '../../services';
import {
  LocationIcon,
  InvoiceIcon,
  PhoneIcon,
  OfficeImage,
  BillsImage,
} from '../_assests';
import { Modal } from '../_common';
import UidForm from './UidForm';
import {
  Wrapper,
  Lk,
  Content,
  How,
  Jaw,
  JawIcon,
  Options,
  OptionPhone,
  OptionInvoice,
  InvoicePreview,
  OptionServiceCenter,
  ServiceCenter,
  CitySelect,
} from './Uid.styled';
import { useQuery } from 'react-query';

export default function Uid() {
  const { regions, currentRegion, onChangeRegion } = useServiceCenters();
  // const [mapMaximized, setMapMaximized] = useState(false);
  const [invoicePreivew, setInvoicePreivew] = useState(false);

  return (
    <ReactResizeDetector handleWidth>
      {({ width }) => (
        <Wrapper>
          <Lk>
            <UidForm />

            <OfficeImage />
          </Lk>

          <Content>
            <How>
              Как узнать или получить уникальный идентификатор личного кабинета?
            </How>

            <Options>
              <OptionPhone>
                <Jaw>
                  <JawIcon>
                    <PhoneIcon />
                  </JawIcon>
                  По телефону колл-центра
                </Jaw>

                <b>8 800 222 11 86</b>

                <span>
                  Режим работы операторов колл-центра с 8:00 до 17:00 в будние
                  дни
                </span>

                <img src="/callcenter.png" alt="Колл-Центр" />
              </OptionPhone>

              <OptionInvoice>
                <Jaw>
                  <JawIcon>
                    <InvoiceIcon />
                  </JawIcon>
                  В счете
                  <br /> на оплату
                </Jaw>

                <Button type="primary" onClick={() => setInvoicePreivew(true)}>
                  Посмотреть счет
                </Button>

                <BillsImage />
              </OptionInvoice>

              <OptionServiceCenter>
                <ServiceCenter>
                  <Jaw>
                    <JawIcon>
                      <LocationIcon />
                    </JawIcon>
                    В центре
                    <br /> обслуживания
                    <br /> клиентов
                  </Jaw>

                  {!!regions.length && (
                    <CitySelect>
                      <label>Выберите регион</label>

                      <Select
                        value={currentRegion}
                        onChange={(val) => onChangeRegion(val)}
                      >
                        {regions.map((region) => (
                          <Select.Option key={region.id} value={region.id}>
                            {region.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </CitySelect>
                  )}
                </ServiceCenter>
              </OptionServiceCenter>
            </Options>
          </Content>

          <Modal
            visible={invoicePreivew}
            onCancel={() => setInvoicePreivew(false)}
            width={720}
          >
            <InvoicePreview src="/invoice.png" alt="Счет" />
          </Modal>
        </Wrapper>
      )}
    </ReactResizeDetector>
  );
}

function useServiceCenters() {
  const { data: regions } = useQuery<Models.Dict[]>('regions', srv.getRegions, {
    initialData: [],
  });

  const [currentRegion, onChangeRegion] = useState(1);

  return { regions, currentRegion, onChangeRegion };
}
