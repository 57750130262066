import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useMutation } from "react-query";
import { Form, Input, Button } from "antd";
import { FormInstance, RuleObject } from "antd/lib/form";
import srv from "../../../services";
import {
  BaseForm,
  ConfirmSuccess,
  Alert,
} from "./RestorePasswordConfirm.styled";

interface Props {
  message?: string;
  byPhone?: boolean;
}

export default function RestorePasswordConfirm({
  message: defaultMessage,
  byPhone = false,
}: Props) {
  const { isLoading, message, error, validatePasswordConfirm, ...formProps } =
    useResetPassword();

  if (message) return <ConfirmSuccess message={message} />;

  return (
    <BaseForm layout="vertical" {...formProps}>
      {!byPhone && <h2>Восстановление пароля</h2>}

      {!!defaultMessage && <Alert type="success" message={defaultMessage} />}

      {byPhone && (
        <Form.Item
          name="approveKey"
          label="Код подтверждения"
          rules={[{ required: true }]}
        >
          <Input autoFocus />
        </Form.Item>
      )}

      <Form.Item
        name="password"
        label="Новый пароль"
        rules={[{ required: true }]}
      >
        <Input.Password autoFocus={!byPhone} autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="password_confirm"
        label="Подтверждение нового пароля"
        dependencies={["password"]}
        rules={[{ required: true }, validatePasswordConfirm]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <Button htmlType="submit" type="primary" loading={isLoading}>
        Сохранить
      </Button>
    </BaseForm>
  );
}

function useResetPassword() {
  const { approveKey } = useParams<any>();
  const {
    mutate: confirm,
    isLoading,
    error,
    data: message,
    reset,
  } = useMutation(srv.restoreUserConfirm);

  const validatePasswordConfirm = useCallback(
    ({ getFieldValue }: FormInstance): RuleObject => ({
      validator(_, value) {
        if (!value || value === getFieldValue("password")) {
          return Promise.resolve();
        }
        return Promise.reject("Пароль и подтверждение не совпадают");
      },
    }),
    []
  );

  const onFinish = useCallback(
    (values) => {
      confirm({ approveKey, ...values });
    },
    [approveKey, confirm]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return {
    isLoading,
    error,
    message,
    validatePasswordConfirm,
    onValuesChange,
    onFinish,
  };
}
