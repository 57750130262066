import styled from "styled-components";
import * as assests from "../../_assests";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
`;

export const Category = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #8f9091;
  width: 165px;
  margin-right: 15px;
`;

export const Add = styled.div`
  cursor: pointer;
  span {
    font-size: 18px;
    line-height: 18px;
    background: linear-gradient(
      180deg,
      #00aae0 39.06%,
      rgba(0, 158, 224, 0.32) 100%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Name = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

export const Required = styled.span`
  font-size: 14px;
  color: red;
  margin-right: 5px;
`;

export const StapletIcon = styled(assests.StapletIcon)`
  width: 25px;
  height: 25px;
  margin-right: 13px;
  cursor: pointer;
`;

export const RemoveIcon = styled(assests.CloseIcon)`
  width: 25px;
  height: 25px;
  margin-left: 13px;
  fill: ${(props) => props.theme.blue};
  cursor: pointer;

  &:hover {
    fill: ${(props) => props.theme.orange};
  }
`;
