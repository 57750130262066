import React, { useState, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { ContentWrapper } from '../../_common';
import SelectContract from '../../_common/SelectContract';
import { SelectBlock } from './ContractsPayment.styled';
import TableMutualSettlements from './TableMutualSettlements';
import { BaseForm } from '../../_common';
import srv from '../../../services';
import { notification, Select } from 'antd';
import { round } from 'lodash';

interface PaymantsObj {
  amount?: number;
  description?: string;
  token?: string;
}

export default function ContractsPayment() {
  const {
    payData,
    docs,
    contractAisId,
    isSubmiting,
    isLoadingTable,
    system,
    onChangeToken,
    changeContract,
    inputSum,
    setInputSum,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    ...formProps
  } = useContractsPayment();
  const { contracts } = useDicts();

  return (
    <>
      <ContentWrapper>
        <SelectBlock>
          {/* <Select
            style={{ width: 200, marginRight: 20 }}
            placeholder="Выберите токен"
            optionFilterProp="children"
            onChange={(val) => onChangeToken(val)}
            value={system}
            options={[
              {
                value: "vostok",
                label: "vostok",
              },
              {
                value: "eric",
                label: "eric",
              },
              {
                value: "ricso",
                label: "ricso",
              },
              {
                value: "yritz",
                label: "yritz",
              },
            ]}
          /> */}
          <SelectContract
            contracts={contracts}
            value={contractAisId}
            changeContract={changeContract}
          />
        </SelectBlock>
        {contractAisId ? (
          <BaseForm layout="vertical" size="large" {...formProps}>
            <TableMutualSettlements
              data={docs}
              isLoadingTable={isLoadingTable}
              isSubmiting={isSubmiting}
              inputSum={inputSum}
              setInputSum={setInputSum}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRows={selectedRows as []}
              setSelectedRows={setSelectedRows}
            />
          </BaseForm>
        ) : null}
      </ContentWrapper>
    </>
  );
}

function useContractsPayment() {
  const requestUrl = window.location.href;
  let tokenValue = '';

  if (requestUrl) {
    if (requestUrl.indexOf('vostok') !== -1) {
      tokenValue = 'vostok';
    }

    if (requestUrl.indexOf('eric') !== -1) {
      tokenValue = 'eric';
    }

    if (requestUrl.indexOf('ricso') !== -1) {
      tokenValue = 'ricso';
    }

    if (requestUrl.indexOf('yritz') !== -1) {
      tokenValue = 'yritz';
    }

    if (requestUrl.indexOf('yugra') !== -1) {
      tokenValue = 'yugra';
    }
  }

  console.log('будет использован токен: ', tokenValue);

  const queryClient = useQueryClient();
  const contracts = queryClient.getQueryData<Models.Contract[]>('contracts');
  const [contractAisId, setContractAisId] = useState<number>(null);
  const [system, setSystem] = useState(tokenValue ? tokenValue : '');
  const [inputSum, setInputSum] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const contractId = contracts.find((i) => i.id === contractAisId);
  const [selectionCounter, setSelectionCounter] = useState(5);

  /* Функция на изменение токена(системы) */
  const onChangeToken = (value) => {
    setSystem(value);
  };

  const {
    data: docs,
    mutate: getDocs,
    isLoading: isLoadingTable,
  } = useMutation(srv.getPaymentDocs);

  useEffect(() => {
    const location = window.location.search;
    const query: any = new URLSearchParams(location);
    const response = String(query.get('success'));

    const amountPaid = sessionStorage.getItem('amountPaid');
    console.log('Сумма платежа: ', amountPaid);

    if (response && response.toLowerCase() === 'true') {
      notification.success({
        message: amountPaid
          ? `Оплата на сумму ${amountPaid} ₽ успешно принята банком!`
          : `Оплата успешно принята банком!`,
        placement: 'bottomRight',
        duration: 30,
        style: {
          width: '500px',
          height: '120px',
          right: '600px',
          bottom: '300px',
          fontWeight: 600,
          border: '3px #008000 solid',
        },
      });
    }
  }, []);

  /* Получение списка документов после выбора номера договора */
  useEffect(() => {
    if (contractAisId) {
      getDocs({
        contractId: contractId?.aisId,
      });
    }
  }, [contractAisId, getDocs, contractId?.aisId]);

  useEffect(() => {
    let selectAll = [];
    let sumSelection = [];
    let counter = 0;

    docs?.docsPayment?.map((item) => {
      if (counter === selectionCounter) {
        return;
      }

      if (selectedRowKeys) {
        selectAll.push(item.fileID);
        sumSelection.push(item);
      }
      counter++;
    });
    setSelectedRowKeys(selectAll);
    setSelectedRows(sumSelection);
  }, [docs?.docsPayment]);

  let amount = 0;
  let paymantObjs: PaymantsObj[] = [];
  selectedRows?.map((item) => {
    if (item.hasOwnProperty('updatedAmount')) {
      if (
        item.updatedAmount < 0 ||
        item.updatedAmount === 0 ||
        item.updatedAmount === ''
      ) {
        amount = 0;
      } else {
        amount = item.updatedAmount;
      }
    } else {
      amount = item.monetaryAmount;
    }

    if (amount !== 0) {
      let debitNum = 1
      if (item.debtType === "Пени") {
        debitNum = 2
      } else if (item.debtType === "Основной долг" || item.debtType === "Полезный отпуск") {
        debitNum = 1
      } else {
        debitNum = 3
      }

      let paymantObj: PaymantsObj = {
        description: `${item.documentNumber};${debitNum ? debitNum : 3};${amount}`,
      };

      return paymantObjs.push(paymantObj);
    }
  });

  /* Метод оплаты с обработкой ответа и прокидыванием на эквайринг */
  const {
    data: payData,
    mutate: onPay,
    isLoading: isSubmiting,
  } = useMutation(srv.paymentDebt, {
    onSuccess: (data) => {
      const error = selectedRows.filter(
        (x) =>
          x.hasOwnProperty('updatedAmount') &&
          (x.updatedAmount < 0 ||
            x.updatedAmount === 0 ||
            x.updatedAmount === '')
      );

      if (error && error.length) {
        notification.error({
          message:
            'Отрицательные и пустые значения будут исключены из рассчета!',
          placement: 'bottomRight',
          duration: 7,
        });
        return;
      }

      window.open(`${data.formUrl}`, '_blank');
    },
    onError: () => {
      const error = selectedRows.filter(
        (x) =>
          x.hasOwnProperty('updatedAmount') &&
          (x.updatedAmount < 0 ||
            x.updatedAmount === 0 ||
            x.updatedAmount === '')
      );

      if (error && error.length) {
        notification.error({
          message:
            'Отрицательные и пустые значения будут исключены из рассчета.',
          placement: 'bottomRight',
          duration: 7,
        });

        return;
      }

      notification.error({
        message: 'При попытке оплаты произошла ошибка!',
        placement: 'bottomRight',
        duration: 7,
      });
    },
  });

  /* Отправка формы */
  const onFinish = () => {
    sessionStorage.setItem('amountPaid', inputSum?.toString());
    console.log('Сумма оплаты: ', inputSum?.toString());

    onPay({
      amount: String(round(inputSum * 100, 0)),
      token: system,
      description: `${contractId.number};${paymantObjs?.map((i) => {
        return i.description;
      })}`,
    });
  };

  /* Метод выбора договора */
  const changeContract = (value) => {
    setContractAisId(value);
  };

  return {
    payData,
    docs: docs?.docsPayment,
    contractAisId,
    system,
    isLoadingTable,
    isSubmiting,
    changeContract,
    onChangeToken,
    onFinish,
    inputSum,
    setInputSum,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
  };
}

function useDicts() {
  const queryClient = useQueryClient();

  const contracts = queryClient.getQueryData<Models.Contract[]>('contracts');
  const services = queryClient.getQueryData<Models.Dict[]>('services');

  return { contracts, services };
}
