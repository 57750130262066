import styled from 'styled-components';
import * as antd from 'antd';
import * as common from '../../_common';

export const BaseForm = styled(common.BaseForm)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 30px;

  @media (min-width: 1160px) {
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 0;
  }

  .ant-select-selector{
    border-color: rgb(103, 153, 64) !important
  }
      
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }

  .ant-input{
    border-color: rgb(103, 153, 64)
  }
  .ant-input:focus{
    box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2) !important
  }
  .ant-select-arrow svg {
    fill: rgb(103, 153, 64);
}
.ant-picker-focused {
  border-color: ${(props) => props.theme.greenRGB};
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
  box-shadow: 0 0 0 2px rgba(103, 153, 64, 0.2);
}

.ant-picker {
  border-color: ${(props) => props.theme.greenRGB};
}
.ant-picker-range .ant-picker-active-bar {
  background: ${(props) => props.theme.greenRGB};
}
`;

export const SubmitButton = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  color: #fff;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  margin-top: 30px;
  &:hover {
    color: #313149;
    background-color: ${(props)=>props.theme.greenRGB}
  }

  @media (min-width: 1160px) {
    width: 300px;
  }
`;

export const Col = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex ?? 1};
  margin: 0 2%;
  padding-top: 20px;

  &:first-child {
    padding-top: 0;
  }

  @media (min-width: 1160px) {
    padding-top: 0;
  }
`;

export const RangePicker = styled(antd.DatePicker.RangePicker)``;
