import React, { useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Form, Input, Button } from "antd";
import srv from "../../../../../services";
import { BaseForm } from "../../../../_common";
import { Alert } from "./PhoneSettingsConfirm.styled";

interface Props {
  message: string;
  onFinish: (message?: string) => void;
}

export default function PhoneSettingsConfirm({ message, onFinish }: Props) {
  const { isSubmiting, error, ...formProps } = useConfirm(onFinish);

  return (
    <>
      {!!message && <Alert type="success" message={message} />}

      <BaseForm layout="vertical" {...formProps}>
        <Form.Item name="approveKey" label="Код" rules={[{ required: true }]}>
          <Input autoFocus />
        </Form.Item>

        {!!error && <Alert type="error" showIcon message={error} />}

        <footer>
          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            loading={isSubmiting}
          >
            Подтвердить
          </Button>

          <Button onClick={() => onFinish()}>Отмена</Button>
        </footer>
      </BaseForm>
    </>
  );
}

function useConfirm(onSuccess: Props["onFinish"]) {
  const queryClient = useQueryClient();
  const {
    mutate: confirm,
    isLoading: isSubmiting,
    isSuccess,
    error,
    data: message,
  } = useMutation(srv.regUserPhoneConfirm);

  const onFinish = useCallback(
    (approveKey) => {
      confirm(approveKey);
    },
    [confirm]
  );

  useEffect(() => {
    if (isSuccess) {
      queryClient.refetchQueries("user");
      onSuccess(message);
    }
  }, [queryClient, isSuccess, message, onSuccess]);

  return { isSubmiting, error, onFinish };
}
