import React from "react";
import Map from "../Map";
import { ZoomInIcon } from "../../../../_assests";

interface Props {
  region: number;
  onMaximize: () => void;
}

export default function MapPreview({ region, onMaximize }: Props) {
  return (
    <Map
      region={region}
      mapZoom={5}
      zoomIcon={<ZoomInIcon />}
      onZoomClick={onMaximize}
    />
  );
}
