import axios from 'axios';
import queryClient from '../queryClient';

export async function getAppeals(): Promise<Models.Appeal[]> {
  const res = await axios.get('feedback/list');
  return res.data;
}

export async function getAppealsQuestion(): Promise<Models.AppealQuestion[]> {
  const res = await axios.get('feedbackais/list');
  return res.data;
}

export async function saveAppeal(values) {
  const { data: feedback } = await axios.post<Models.Appeal>('feedback', values);
  const { fileTypes, limited } = queryClient.getQueryData<Models.FileTypes>(['fileTypes', values.topicId]) ?? {};

  if (fileTypes?.length) {
    await axios.all(fileTypes.map(async (fileType) => {
      if (values?.[`fileType_${fileType?.id}`]) {
        await saveAppealFile(values[`fileType_${fileType.id}`], feedback.id, fileType.id);
      }
    }));
  }

  if (!limited && values?.files?.length) {
    await axios.all(values.files.map(async ({ originFileObj }) => {
      await saveAppealFile(originFileObj, feedback.id);
    }));
  }
}

export async function saveAppealFile(file, feedbackId, fileTypeId?) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('feedbackId', String(feedbackId));
  if (fileTypeId) formData.append('fileTypeId', String(fileTypeId));

  await axios.post('feedback/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function getFileTypes(topicId: number) {
  const res = await axios.get(`file-types/${topicId}`);
  return res.data;
}

export async function saveAppealAskQuestion(payload){
  const res = await axios.post<Models.AppealQuestion>(`feedbackais`, payload);
  return res.data;
}
