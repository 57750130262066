import axios from 'axios';

export async function getAnswerTypes(): Promise<Models.Dict[]> {
  const res = await axios.get('dictionary/answerdelivery');
  return res.data;
}

export async function getServices(): Promise<Models.Dict[]> {
  const res = await axios.get('dictionary/requestservice');
  return res.data;
}

export async function getTerritories(): Promise<Models.Dict[]> {
  const res = await axios.get('dictionary/territory');
  return res.data;
}

export async function getTopics(): Promise<Models.Dict[]> {
  const res = await axios.get('dictionary/requesttopic');
  return res.data;
}

export async function getWorkDocTypes(): Promise<Models.Dict[]> {
  const res = await axios.get('dict/workDocTypes/List');
  return res.data.items;
}
