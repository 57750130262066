import React from 'react';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import { LocationIcon, YouTubeIcon, VkIcon, PhoneIcon } from '../../_assests';
import {
  Wrapper,
  Copyright,
  Privacy,
  Address,
  Contacts,
  Contact,
  Phone,
  Social,
} from './Footer.styled';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export default function Footer({ children, className }: Props) {
  const date = useQueryClient().getQueryData('date');

  return (
    <Wrapper className={className}>
      {children}

      <Copyright href="https://yugra-ecology.ru" target="_blank">
        ©{moment(date).format('YYYY')} Акционерное общество «Югра-Экология»
      </Copyright>

      <Privacy>
        Пользовательское соглашение
      </Privacy>

      <Privacy
        href={`${window.location.origin}/privacy_policy.pdf`}
        target="_blank"
      >
        Политика конфиденциальности
      </Privacy>

      <Address>
        <LocationIcon />
        <span>628011, Ханты-Мансийск ул. Привольная, 15</span>
      </Address>

      <Phone>
        <PhoneIcon />8 800 222 11 86
      </Phone>

      {/* <Contacts>
        <Contact>
          <a
            href="https://www.youtube.com/channel/UCPRAhaGv-4JIqj5kmymRsrw"
            target="_blank"
            rel="noreferrer"
          >
            <YouTubeIcon />
          </a>
        </Contact>

        <Contact>
          <a
            href="https://vk.com/vostok_electra"
            target="_blank"
            rel="noreferrer"
          >
            <VkIcon />
          </a>
        </Contact>
      </Contacts>
      <Social>Мы в социальных сетях</Social> */}
    </Wrapper>
  );
}
