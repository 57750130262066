import React from "react";
import Map from "../Map";
import { ZoomOutIcon } from "../../../../_assests";
import { MapWrapper } from "./MapFull.styled";

interface Props {
  region: number;
  onMinimize?: () => void;
}

export default function MapFull({ region, onMinimize }: Props) {
  return (
    <MapWrapper>
      <Map
        region={region}
        mapZoom={6}
        zoomIcon={<ZoomOutIcon />}
        onZoomClick={onMinimize}
      />
    </MapWrapper>
  );
}
