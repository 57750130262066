import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Input, Alert } from 'antd';
import { FormInstance, RuleObject } from 'antd/lib/form';
import srv from '../../../services';
import { BaseForm, Info, SubmitButton } from './UidForm.styled';

export default function UidForm() {
  const { contragent, isLoading, error, ...formProps } = useUser();

  const validateUid = useCallback(({ getFieldValue }: FormInstance): RuleObject => ({
    validator() {
      if (getFieldValue('uid') && !Number.isInteger(Number(getFieldValue('uid')))) {
        return Promise.reject('Введен некорректный идентификатор');
      }
      return Promise.resolve();
    },
  }), []);

  return (
    <BaseForm
      layout="vertical"
      size="large"
      requiredMark={false}
      {...formProps}
    >
      <Form.Item
        name="uid"
        label="Введите уникальный идентификатор личного кабинета"
        rules={[{ required: true }, validateUid]}
      >
        <Input autoFocus />
      </Form.Item>

      {!!contragent && (
        <Info>
          <h2>{contragent.companyName}</h2>

          {!!contragent.legalAddress && (
            <div>{contragent.legalAddress}</div>
          )}

          {!!contragent.inn && (
            <div><b>ИНН</b> {contragent.inn}</div>
          )}

          {!!contragent.kpp && (
            <div><b>КПП</b> {contragent.kpp}</div>
          )}
        </Info>
      )}

      {!!error && <Alert type="error" showIcon message={error} />}

      <footer>
        <SubmitButton htmlType="submit" loading={isLoading}>
          Далее
        </SubmitButton>
      </footer>
    </BaseForm>
  );
}

function useUser() {
  const queryCache = useQueryClient();

  const [contragent, setContragent] = useState<Omit<Models.Contragent, 'id' | 'uid'>>(null);

  const getContragent = useMutation(srv.getUidInfo, {
    onSuccess: data => setContragent(data),
    onError: () => setContragent(null),
  });

  const setUid = useMutation(srv.setUserUid);

  const onFinish = useCallback(({ uid }) => {
    if (contragent) {
      setUid.mutate(uid);
    } else {
      getContragent.mutate(uid);
    }
  }, [contragent, setUid, getContragent]);

  const onValuesChange = useCallback(() => {
    setContragent(null);
  }, []);

  useEffect(() => {
    if (setUid.isSuccess) {
      queryCache.invalidateQueries('user');
    }
  }, [queryCache, setUid.isSuccess]);

  return {
    contragent,
    isLoading: getContragent.isLoading || setUid.isLoading,
    error: getContragent.error || setUid.error,
    onValuesChange,
    onFinish
  };
}
