import styled from "styled-components";
import * as antd from "antd";

export const TablePay = styled((props) => <antd.Table {...props} />)`
  .ant-table table {
    text-align: center;
  }
  .ant-table-thead {
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #a3a3a5;
    padding: 10px;
    font-weight: normal;
    vertical-align: baseline;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    text-align: center;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }
`;

export const InputPay = styled((props) => <antd.InputNumber {...props} />)`
  width: 120px;
  border: none;
  border-bottom: 2px solid #2b314f;

  .ant-input-number-input:placeholder-shown {
    font-size: 12px;
  }
`;
