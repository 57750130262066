import styled from 'styled-components';
import * as antd from 'antd';
import * as common from '../_common';

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('/slide1.png');
  background-position: left top;
  background-repeat: no-repeat;
  padding: 0px !important;

  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
    background-image: none !important;
    padding: 0px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    background-image: url('/slide1.png');
    background-size: 60%, 30%, 30%;
    background-repeat: no-repeat;
    background-position: left bottom 95px !important;
    padding: 0px !important;
  }

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    background-image: url('/slide1.png');
    background-size: 60% !important;
    background-position: left bottom 95px !important;
    background-repeat: no-repeat;
    padding: 0px !important;
  }

  @media (max-width: 1899px) {
    display: flex;
    justify-content: space-between;
    background-image: url('/slide1.png');
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: left 350px bottom 270px;
  }

  @media (min-width: 1900px) {
    display: flex;
    justify-content: space-between;
    background-image: url('/slide1.png');
    background-repeat: no-repeat;
    background-position: left 470px bottom 120px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 15px 30px !important;
    border-radius: 0px 0px 20px 20px;
    padding: 15px 30px !important;
  }

  @media (max-width: 768px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 20px 20px;
  }

  @media (max-width: 1024px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 20px 20px;
  }

  @media (max-width: 1899px) {
    padding: 30px 30px 0;
  }

  @media (min-width: 1900px) {
    padding: 30px 30px 0;
  }
`;

export const LogoBlock = styled.div`
  display: flex;

  @media (max-width: 576px) {
    margin: auto;
  }
`;

export const Logo = styled.img`
  height: 150px;
`;

export const Title = styled.div`
  margin-left: 43px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-left: 40px;

  h1 {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    max-width: 140px;
    margin-bottom: 8px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #00aeef;
    letter-spacing: 1.3px;
    margin-top: 10px;
  }

  @media (max-width: 576px) {
    margin-left: 10px;
    text-align: right;

    h1 {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      max-width: 140px;
      letter-spacing: 0px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 37px;
      letter-spacing: 0px;
      color: #009ee0;
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 37px;
      color: #009ee0;
      letter-spacing: 0px;
    }
  }
`;
export const PhoneBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    display: none;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Phone = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;

  svg {
    fill: #2a5caa;
    height: 30px;
    margin-right: 16px;
  }
`;

export const Notification = styled.div`
  margin-left: auto;
  cursor: pointer;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 110px;
  margin-bottom: 40px;

  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

export const Auth = styled.div`
  max-width: 465px;
  margin-left: -60px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  border-radius: 20px;

  @media (max-width: 576px) {
    margin-left: 0;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
  }
  @media (min-width: 1024px) {
    margin-left: 0;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
  }
  @media (min-width: 1024px) {
    margin-left: 0;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
  }
`;

export const Tabs = styled(antd.Tabs)`
  .ant-tabs-nav {
    padding-left: ${(props) =>
      props.tabBarGutter ? `${props.tabBarGutter}px` : ' 1.5rem'};
    padding-top: 10px;
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    padding: 0.33rem 0;

    &-btn {
      font-size: 1.5rem;
      font-weight: 500;
      color: #8f9090;
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2b314f;
    }

    &-disabled {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #679940;
    height: 0.15rem !important;
  }

  .ant-tabs-tabpane {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem 30px;

    @media (min-width: 480px) {
      padding: 34px 60px;
    }
  }
`;

export const Water = styled.div`
  max-width: 310px;
  margin-left: auto;
  margin-top: 25px;

  i {
    position: relative;
    font-weight: bold;
    font-size: 80px;
    font-style: normal;
    line-height: 60px;
    height: 100px;
    width: 68px;
    margin-left: 30px;
    margin-bottom: 10px;
    color: white;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 15px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #679940;
      z-index: -1;
      top: 5px;
    }
  }

  article {
    min-height: 290px;

    > h2 {
      font-weight: 700;
      font-size: 35px;
      line-height: 36px;
      margin-top: 60px;
      text-shadow: 1px 1px 2px #fff;
    }

    > p {
      font-size: 16px;
      line-height: 18px;
      margin: 15px 15px 0 0;
      min-height: 55px;
      text-shadow: 1px 1px 2px #fff;
    }
  }

  > span {
    display: block;
    margin-top: 45px;

    svg {
      width: 32px;
      height: 32px;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  > button {
    margin-top: 45px;
  }
  > button:hover {
    background-color: #679940;
  }

  @media (max-width: 576px) {
    display: none !important;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    i {
      position: relative;
      font-weight: bold;
      font-size: 50px;
      font-style: normal;
      line-height: 40px;
      height: 70px;
      width: 30px;
    }

    article {
      min-height: 150px;
      max-width: 300px;
      > h2 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
    }
    > span {
      display: flex;
      flex-direction: row !important;
      margin-top: 10px;
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    i {
      position: relative;
      font-weight: bold;
      font-size: 50px;
      font-style: normal;
      line-height: 40px;
      height: 70px;
      width: 30px;
    }

    article {
      min-height: 150px;
      max-width: 300px;
      z-index: 2;
      > h2 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
    }
    > span {
      display: flex;
      flex-direction: row !important;
      margin-top: 10px;
    }
  }

  @media (max-width: 1899px) {
    margin-right: 15px;
  }

  @media (min-width: 1900px) {
    margin-right: 100px;
  }
`;

export const Manual = styled((props) => (
  <common.DownloadButton type="primary" {...props} />
))`
  background-color: #679940;
  color: #313149;
  height: 40px;
  display: inline-block;
  width: 100%;
  padding: 0 25px;
  font-size: 20px;
  line-height: 16px;
  white-space: pre-wrap;
  @media (max-width: 576px) {
    display: none;
  }

  @media (max-width: 768px) {
    height: 54px;
    width: 100%;
    padding: 0 25px;
    font-size: 20px;
    line-height: 16px;
    border-width: 3px;
    white-space: pre-wrap;
  }

  @media (max-width: 1024px) {
    height: 54px;
    width: 100%;
    padding: 0 25px;
    font-size: 20px;
    line-height: 16px;
    border-width: 3px;
    white-space: pre-wrap;
  }
`;

export const Footer = styled(common.Footer)`
  margin-top: auto;
  padding: 0;
`;

export const DrawerBlock = styled((props) => <antd.Drawer {...props} />)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8f9090;

  .ant-drawer-header {
    text-align: center;
  }
  ol {
    padding: 0;
  }
  li {
    padding-left: 10px;
    list-style-type: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background-color: #679940;
    border: 3px solid transparent;
    color: #2b314f;
  }
`;

export const DrawerButton = styled((props) => <antd.Button {...props} />)`
  font-size: 18px;
  line-height: 18px;
  width: 250px;
  margin-left: 25%;
  background-color: #679940;
  color: white;
`;
