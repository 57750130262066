import styled from 'styled-components';
import * as antd from 'antd';

export const ModalConf = styled(antd.Modal)`
  .ant-modal-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    text-align: center;
    border: none;
  }

  .ant-modal-body {
    padding: 20px 20px 0 20px;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2b314f;
`;

export const ButtonCancel = styled(antd.Button)`
  width: 207px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  color: #2b314f;
`;

export const ButtonConfirm = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  color: #313149;
  width: 207px;
  font-size: 18px;
  line-height: 18px;
`;
