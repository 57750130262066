import styled from "styled-components";
import * as antd from "antd";

export const ModalWarn = styled(antd.Modal)`
  .ant-modal-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  .ant-modal-footer {
    text-align: center;
    border: none;
  }
  .ant-modal-body {
    padding: 20px 20px 0 20px;
  }
`;
export const ButtonCancel = styled(antd.Button)`
  margin: auto;
  width: 150px;
  font-size: 18px;
  line-height: 18px;
  color: #2b314f;
`;
