import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import { Alert, Spin } from 'antd';
import srv from '../../../services';
import ConfirmSuccess from '../ConfirmSuccess';
import { Wrapper } from './RegistrationConfirm.styled';

export default function RegistrationConfirm() {
  const { isLoading, error, message } = useConfirm();

  return (
    <Wrapper>
      {(() => {
        if (isLoading) return (
          <Spin size="large" />
        );

        if (error) return (
          <Alert type="error" showIcon message={error} />
        );

        return (
          <ConfirmSuccess message={message} />
        );
      })()}
    </Wrapper>
  );
}

function useConfirm() {
  const { approveKey } = useParams<any>();
  const { mutate: confirm, isLoading, error, data: message } = useMutation(srv.regUserConfirm);

  useEffect(() => {
    confirm(approveKey);
  }, [approveKey, confirm]);

  return { isLoading, error, message };
}
