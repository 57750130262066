import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import moment from "moment";
import { ContentWrapper } from "../../_common";
import { ZoomInIcon, ZoomOutIcon } from "../../_assests";
import Connections from "./Connections";
import {
  Content,
  Contract,
  ContractData,
  ContractZoom,
  ContractType,
  ContractNumber,
  PrintNumber,
  ContractParam,
  ContractConnections,
} from "./Contracts.styled";

export default function Contracts() {
  const { contracts, maximizedContract, onMaximize } = useContracts();

  return (
    <ContentWrapper>
      <Content>
        {contracts?.map((contract) => (
          <Contract
            key={contract.id}
            maximized={maximizedContract === contract.id}
          >
            <ContractZoom onClick={() => onMaximize(contract.id)}>
              {maximizedContract === contract.id ? (
                <ZoomOutIcon />
              ) : (
                <ZoomInIcon />
              )}
            </ContractZoom>

            <ContractData>
              <ContractType>
                <u>Тип договора</u> {contract.type}
              </ContractType>

              <PrintNumber>
                <u>Номер договора</u> {contract.printNumber}
              </PrintNumber>

              <ContractNumber>
                <u>Системный номер</u> {contract.number}
              </ContractNumber>

              <ContractParam>
                <u>Дата заключения</u>{" "}
                {moment(contract.contractDate).format("DD.MM.YYYY")}
              </ContractParam>

              <ContractParam>
                <u>Автопролонгация</u>{" "}
                {contract.autoContinuation ? "Да" : "Нет"}
              </ContractParam>

              <ContractParam>
                <u>Состояние</u> {contract.state}
              </ContractParam>
              {/* 
              <ContractParam>
                <u>Сумма долг</u> {contract.indebtedness}
              </ContractParam> */}

              {/* <ContractParam>
                <u>Сумма аванс</u> {contract.prepaidExpense}
              </ContractParam> */}
            </ContractData>

            {maximizedContract === contract.id && (
              <ContractConnections>
                <Connections contractId={contract.id} />
              </ContractConnections>
            )}
          </Contract>
        ))}
      </Content>
    </ContentWrapper>
  );
}

function useContracts() {
  const contracts =
    useQueryClient().getQueryData<Models.Contract[]>("contracts");

  const [maximizedContract, setMaximizedContract] = useState(null);

  const onMaximize = useCallback(
    (id) => {
      if (maximizedContract === id) {
        setMaximizedContract(null);
      } else {
        setMaximizedContract(id);
      }
    },
    [maximizedContract]
  );

  return { contracts, maximizedContract, onMaximize };
}
