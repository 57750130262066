import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { notification, Popconfirm } from "antd";
import qs from "query-string";
import srv from "../../../services";
import { ContentWrapper, Modal } from "../../_common";
import PasswordSettings from "./PasswordSettings";
import EmailSettings from "./EmailSettings";
import EmailConfirm from "./EmailConfirm";
import PhoneSettings from "./PhoneSettings";
import {
  Content,
  Item,
  MainInfo,
  MainInfoBlock,
  MainBlock,
  MainData,
  ContactInfo,
  PhoneMail,
  ContactTitle,
  Title,
  Underline,
} from "./Settings.styled";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

export default function Settings() {
  const user = useQueryClient().getQueryData<Models.User>("user");
  const { contragent, isRemoving, onRemoveContragent } = useContragent();
  const { currentModal, onCloseModal, onOpenModal } = useModal();

  const [methodValue, setMethodValue] = React.useState(0);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState<AlertColor>("error");

  useLayoutEffect(() => {
    srv.getUserMethod().then((response) => {
      if (response && response.data) {
        setMethodValue(Number(response.data));
      }
    });
  }, []);

  const onFinish = useCallback(
    (successMessage?: string) => {
      if (successMessage) {
        notification.success({
          message: successMessage,
          placement: "bottomRight",
          duration: 7,
        });
      }
      onCloseModal();
    },
    [onCloseModal]
  );

  const handleSendingMehod = () => {
    const payload = {
      newValue: methodValue,
    };

    srv.saveUserMethod(payload).then((response) => {
      const messageSuccess = "Способ получения чека был успешно изменен";
      const messageError = "Ошибка изменения способа получения чека";

      if (!response) {
        setSnackBarMessage(messageSuccess);
        setSnackBarType("success");
        setSnackBarOpen(true);
      } else {
        setSnackBarMessage(messageError);
        setSnackBarType("error");
        setSnackBarOpen(true);
      }
    });
  };

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setMethodValue(Number(inputValue));
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <React.Fragment>
      <ContentWrapper isLoading={isRemoving || !contragent}>
        <Content>
          <MainInfo>
            <Title>
              <h2>Основная информация</h2>
              <Underline />
            </Title>
            <MainInfoBlock>
              <MainBlock>
                <Item kind="company">
                  <b>{contragent.companyName}</b>
                </Item>
                <Item kind="address">
                  <u>Юридический адрес</u>
                  <b>{contragent.legalAddress}</b>
                </Item>
              </MainBlock>
              <MainData>
                <Item kind="inn">
                  <u>ИНН</u>
                  <b>{contragent.inn}</b>
                </Item>
                <Item kind="kpp">
                  <u>КПП</u>
                  <b>{contragent.kpp}</b>
                </Item>
                {/* <Item kind="ogrn">
                <u>ОГРН</u>
                <b>{contragent.kpp}</b>
              </Item> */}
              </MainData>

              <MainData>
                <Item kind="inn">
                  <h1>Способ получения чека</h1>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={methodValue}
                    value={methodValue}
                    onChange={handleMethodChange}
                    name="radio-buttons-group"
                    className="radio"
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio sx={{
                        '&, &.Mui-checked': {
                          color: '#679940',
                        },
                      }}/>}
                      label={
                        <b>{`+7 ${user.phone.slice(0, 3)} ${user.phone.slice(
                          -7,
                          6
                        )}-${user.phone.slice(-4, 8)}-${user.phone.slice(
                          -2,
                          10
                        )}`}</b>
                      }
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio sx={{
                        '&, &.Mui-checked': {
                          color: '#679940',
                        },
                      }}/>}
                      label={user.email}
                    />
                  </RadioGroup>
                  <button onClick={() => handleSendingMehod()}>Изменить</button>
                </Item>
              </MainData>
            </MainInfoBlock>
          </MainInfo>
          <ContactInfo>
            <ContactTitle>
              <h2>Контактная информация</h2>
              <Underline />
            </ContactTitle>
            <div style={{ display: "flex" }}>
              <PhoneMail>
                <Item kind="phone">
                  <u>Телефон</u>
                  <b>{`+7 ${user.phone.slice(0, 3)} ${user.phone.slice(
                    -7,
                    6
                  )}-${user.phone.slice(-4, 8)}-${user.phone.slice(
                    -2,
                    10
                  )}`}</b>
                  <button onClick={() => onOpenModal("phone")} hidden>
                    {user.phone ? "Изменить" : "Добавить"}
                  </button>
                </Item>
                <Item kind="email">
                  <u>E-mail</u>
                  <b>{user.email}</b>

                  <Switch>
                    <Route
                      path="/settings/change-email/:approveKey"
                      render={() => <EmailConfirm onSuccess={onFinish} />}
                    />

                    <Route
                      render={() => (
                        <button onClick={() => onOpenModal("email")}>
                          {user.email ? "Изменить" : "Добавить"}
                        </button>
                      )}
                    />
                  </Switch>
                </Item>
              </PhoneMail>
              <Item kind="password">
                <u>Пароль</u>
                <b>*************</b>
                <button onClick={() => onOpenModal("password")}>
                  Изменить
                </button>
              </Item>
              {/* <AddressInfo>
              <Item kind="address-mail">
                <u>Почтовый адрес</u>
                <b>Тут какой-то адрес</b>
              </Item>
            </AddressInfo> */}
            </div>

            <Item kind="remove" hidden>
              <u>Вы можете</u>
              <Popconfirm
                title="Вы уверены?"
                okButtonProps={{ danger: true }}
                okText="Да"
                cancelText="Отмена"
                onConfirm={onRemoveContragent}
              >
                <button>отвязать контрагента</button>
              </Popconfirm>
            </Item>

            <Modal
              destroyOnClose
              footer={null}
              closable={false}
              maskClosable={false}
              visible={!!currentModal}
              onCancel={onCloseModal}
            >
              {(() => {
                switch (currentModal) {
                  case "password":
                    return <PasswordSettings onFinish={onFinish} />;
                  case "email":
                    return <EmailSettings onFinish={onFinish} />;
                  case "phone":
                    return <PhoneSettings onFinish={onFinish} />;
                  default:
                    return null;
                }
              })()}
            </Modal>
          </ContactInfo>
        </Content>
      </ContentWrapper>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBarType}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

function useContragent() {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<Models.User>("user");
  const contragent = queryClient.getQueryData<Models.Contragent>("contragent");

  const {
    mutate: removeUid,
    isIdle,
    isSuccess: isRemoveSuccess,
  } = useMutation(srv.removeUserUid);

  const onRemoveContragent = useCallback(() => {
    removeUid(user.uid);
  }, [user, removeUid]);

  const clear = useCallback(async () => {
    await queryClient.invalidateQueries("user");
    queryClient.removeQueries("contragent");
    queryClient.removeQueries("contracts");
  }, [queryClient]);

  useEffect(() => {
    if (isRemoveSuccess) clear();
  }, [clear, isRemoveSuccess]);

  return { contragent, isRemoving: !isIdle, onRemoveContragent };
}

function useModal() {
  const { pathname, search } = useLocation();

  const { push } = useHistory();

  const query = useMemo(() => {
    return qs.parse(search);
  }, [search]);
  console.log(qs.parse(search));

  const currentModal = useMemo(() => {
    return query?.edit || null;
  }, [query]);

  const onOpenModal = useCallback(
    (edit: string) => {
      console.log(push(`${pathname}?${qs.stringify({ ...query, edit })}`));

      push(`${pathname}?${qs.stringify({ ...query, edit })}`);
    },
    [push, pathname, query]
  );

  const onCloseModal = useCallback(() => {
    push(`${pathname}?${qs.stringify({ ...query, edit: undefined })}`);
  }, [push, pathname, query]);

  return { currentModal, onOpenModal, onCloseModal };
}
