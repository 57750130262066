import styled from 'styled-components';
import * as antd from 'antd';
import * as common from '../_common';

export const BaseForm = styled(common.BaseForm)`
  .ant-alert {
    margin-top: 30px;
    margin-bottom: -20px;
  }

  button[type='submit'] {
    margin-top: 20px;
    width: 100%;
  }

  button[type='submit']:hover {
    background-color: #679940;
  }
`;

export const Title = styled.div`
  color: #2b314f;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  margin-bottom: 25px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 20px;
    color: #8f9090;
  }
`;

export const Anchor = styled.div`
  display: block;
  color: #5492f2;
  font-size: 16px;
  line-height: 14px;
  margin-top: 28px;
  cursor: pointer;
`;

export const BackButton = styled((props) => <antd.Button {...props} />)`
  font-size: 18px;
  line-height: 18px;
  margin-top: 20px;
  width: 100%;
`;

export const SubmitButton = styled((props) => (
  <antd.Button type="primary" {...props} />
))`
  background-color: #679940;
  color: #313149;
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 20px;
`;

export const RetryButton = styled((props) => (
  <antd.Button type="default " {...props} />
))`
  border-width: 1px;
  border-radius: 90px;
  margin-left: 15px;
  height: 30px;
`;

export const AuthorizationApp = styled((props) => (
  <common.DownloadButton type="link" {...props} />
))`
  display: flex;
  p {
    margin-left: 10px;
    color: #679940;
  }

  p:hover {
    color: #0e94f5;
  }
`;

export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 7px;

  div {
    margin-left: 10px;
    color: #679940;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
  }

  a {
    display: flex;
    color: #679940;
  }

  a:hover {
    color: #0e94f5;
  }
`;

export const PrintButton = styled((props) => (
  <antd.Button type="link " {...props} />
))`
  display: flex;
  margin-top: 15px !important;
  padding: 0 7px;

  p {
    color: #679940;
    margin-left: 10px;
  }
  p:hover {
    color: #0e94f5;
  }
`;

export const Agreement = styled.div`
  display: flex;
`;

export const Error = styled.div`
  .ant-alert-error {
    background: none !important;
    border: none !important;
  }
`;

export const NoRegistration = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const StepAuthorization = styled.div``;
