import styled from "styled-components";
import * as antd from "antd";
import * as assests from "../../_assests";

export const UploadDragger = styled(antd.Upload.Dragger)`
  min-height: 100px;
  background-color: transparent !important;
  color: red;

  .ant-upload-drag-container {
    display: flex !important;
    align-items: center;
    margin: -5px 13px;
    font-weight: 500;
    text-align: left !important;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StapletIcon = styled(assests.StapletIcon)`
  width: 28px;
  margin-right: 13px;
`;

export const CountFiles = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2b314fb2;
`;
