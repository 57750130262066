import styled from 'styled-components';
import * as antd from 'antd';
import * as common from '../../_common';

export const BaseForm = styled(common.BaseForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-form-item-label > label {
    font-size: 12px;
  }

  footer {
    margin-top: 30px;
  }
`;

export const SubmitButton = styled(props => <antd.Button type="primary" {...props} />)`
  width: 100%;
  height: 48px;
  font-size: 20px;
  line-height: 20px;
  border-width: 3px;
  margin-left: auto;
`;

export const Info = styled.div`
  h2 {
    font-size: 1.13em;
    margin-bottom: 0.3rem;
  }

  > div {
    margin-bottom: 0.3rem;

    b {
      font-weight: 500;
      color: #333;
      margin-right: 0.1em;

      &::after {
        content: ':';
      }
    }
  }
`;
