import styled from 'styled-components';

export const Content = styled.div`
  .ant-divider-horizontal {
    margin: 15px 0 15px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #2b314f;
`;

export const ConnectName = styled.div`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    42.69deg,
    #fff33b -5.54%,
    #fee72e -1.35%,
    #fed51b 6.72%,
    #fdca10 15%,
    #fdc70c 23.9%,
    #679940 64.56%,
    #ed683c 87.51%,
    #679940 99.24%
  );
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #2b314f;
`;

export const DeviceName = styled.div`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #a3a3a5;
`;

export const NumberDevice = styled.div`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #a3a3a5;
`;
