import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Button, Alert, Form, Input } from 'antd';
import srv from '../../../../services';
import { PhoneInput } from '../../../_common';
import { BaseForm } from '../../../_common';
import PhoneSettingsConfirm from './PhoneSettingsConfirm';

interface Props {
  onFinish: (message?: string) => void;
}

export default function PhoneSettings({ onFinish }: Props) {
  const { isSubmiting, isSuccess, error,  message,...formProps } = useForm();

  if (isSuccess) return (
    <PhoneSettingsConfirm
      message={message}
      onFinish={onFinish}
    />
  );

  return (
    <BaseForm
      layout="vertical"
      {...formProps}
    >
      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item name="newValue" label="Новый телефон" rules={[{ required: true }]}>
        <PhoneInput />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <footer>
        <Button htmlType="submit" type="primary" loading={isSubmiting}>
          Сохранить
        </Button>

        <Button onClick={() => onFinish()}>
          Отмена
        </Button>
      </footer>
    </BaseForm>
  );
}

function useForm() {
  const {
    mutate: submit, isLoading: isSubmiting, isSuccess, error, data: message
  } = useMutation(srv.changeUserPhone);

  const onFinish = useCallback(values => {
    submit(values);
  }, [submit]);

  return { isSubmiting, isSuccess, message, error, onFinish };
}
