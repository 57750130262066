import { Select } from "antd";
import { SelectContracts } from "./SelectContract.styled";

interface Props {
  changeContract: (value) => void;
  contracts: Models.Contract[];
  value: number;
}

export default function SelectContract({
  changeContract,
  contracts,
  value,
}: Props) {
  return (
    <SelectContracts
      allowClear
      showSearch
      optionFilterProp="children"
      placeholder="Выберите № договора"
      value={value}
      onChange={changeContract}
    >
      {contracts?.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.printNumber}
        </Select.Option>
      ))}
    </SelectContracts>
  );
}
