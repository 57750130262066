import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Input, Alert } from 'antd';
import srv from '../../../../services';
import {
  BaseForm,
  Title,
  Description,
  Anchor,
  SubmitButton,
  BackButton,
} from '../../Forms.styled';

interface Props {
  onForgot: () => void;
  phone: string;
  onBack: () => void;
}

export default function PasswordInput({ onForgot, phone, onBack }: Props) {
  const { isLoading, error, ...formProps } = useAuth();

  return (
    <BaseForm layout="vertical" size="large" {...formProps}>
      <Title>
        <Description>
          <h4>Для входа в систему введите пароль</h4>
          <p>Введите пароль, указанный Вами при первой авторизации</p>
        </Description>
      </Title>

      <Form.Item
        name="login"
        label="Телефон"
        initialValue={phone}
        hidden={true}
      ></Form.Item>

      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password autoFocus />
      </Form.Item>

      <Anchor onClick={onForgot}>Забыли пароль?</Anchor>

      {!!error && <Alert type="error" showIcon message={error} />}

      <BackButton size="large" onClick={onBack}>
        Назад
      </BackButton>

      <SubmitButton
        htmlType="submit"
        size="large"
        loading={isLoading}
        style={{ color: 'white' }}
      >
        Войти в личный кабинет
      </SubmitButton>
    </BaseForm>
  );
}

function useAuth() {
  const queryClient = useQueryClient();
  const {
    mutate: authenticate,
    isLoading,
    error,
    reset,
  } = useMutation(srv.authUser, {
    onSuccess: (data) => queryClient.setQueryData<any>('user', data),
  });

  const onFinish = useCallback(
    (values) => {
      authenticate(values);
    },
    [authenticate]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  return { isLoading, error, onValuesChange, onFinish };
}
