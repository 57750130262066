import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { Form, Input, Checkbox, Modal } from "antd";
import { CloseIcon } from "../../../_assests";
import srv from "../../../../services";
import PasswordSettings from "../PasswordSettings";
import {
  BaseForm,
  SubmitButton,
  RetryButton,
  BackButton,
} from "../../Forms.styled";
import {
  Alert,
  Actions,
  Title,
  InputBlock,
  Agreement,
} from "./PinInput.styled";
import { Styles } from "../../../_common/Modal/Modal.styled";
interface Props {
  message: string;
  phone: string;
  onBack: () => void;
}

export default function PinInput({
  message: defaultMessage,
  phone,
  onBack,
}: Props) {
  const { isSubmiting, isResending, isSuccess, error, onFinish, onResend } =
    useConfirm(defaultMessage, phone);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  if (isSuccess) return <PasswordSettings phone={phone} onBack={onBack} />;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCheckAllChange = (e) => {
    setCheckAll(e.target.checked);
  };

  return (
    <>
      {/* {!!message && <Alert type="success" message={message} />} */}

      <BaseForm layout="vertical" onFinish={onFinish}>
        <Title>
          <h4>Введите Пин-Код для подтверждения номера мобильного телефона </h4>
          <p>
            Мы отправили на Ваш номер телефона ПИН-Код для подтверждения номера
            мобильного телефона по SMS
          </p>
        </Title>
        <InputBlock>
          <Form.Item
            name="approveKey"
            label="ПИН-Код"
            rules={[{ required: true }]}
          >
            <Input autoFocus style={{ width: "150px" }} />
          </Form.Item>
          <RetryButton
            loading={isResending}
            disabled={isSubmiting}
            onClick={() => onResend(phone)}
          >
            Выслать код повторно
          </RetryButton>
        </InputBlock>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Необходимо согласиться с условиями пользования"
                      )
                    ),
            },
          ]}
        >
          <Agreement>
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <a href="/#" onClick={showModal}>
                Я согласен с условиями использования личного кабинета
              </a>
            </Checkbox>
          </Agreement>
        </Form.Item>

        {!!error && <Alert type="error" showIcon message={error} />}

        <Modal
          title="Пожалуйста, ознакомьтесь с условием использования личного кабинета"
          visible={isModalVisible}
          centered
          width="780px"
          footer={null}
          style={{ color: "#8F9090" }}
          onCancel={handleCancel}
          closeIcon={<CloseIcon />}
        >
          <p>
            <strong>
              <u>
                Дальнейшим использованием Вы выражаете свое согласие и не
                возражаете
              </u>
            </strong>
            , чтобы <strong>АО «ЭК «Восток»</strong> от своего имени и (или) от
            имени иных поставщиков ресурсов (при наличии договорных отношений с
            иными поставщиками) отправляли в настоящий Личный кабинет сообщения,
            любые письма, извещения, формируемые поставщиком ресурса в рамках
            исполнения соответствующего договора (государственного /
            муниципального контракта), в т.ч. уведомления о введении ограничения
            режима потребления электрической энергии. <br /> <br />
            Одновременно с этим, Вы подтверждает, что с дальнейшими юридическими
            последствиями направления посредствам личного кабинета уведомления о
            введении ограничения режима потребления электрической энергии
            <strong> ознакомлен(-а) *</strong>. <br />
            <br /> Мне известно, что настоящее СОГЛАСИЕ по работе в личном
            кабинете пользователя может быть отозвано мной путем направления
            письменного заявления в адрес <strong>АО «ЭК «Восток»</strong>.
          </p>
          <Form.Item name="agreement" valuePropName="checked">
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAll}
              onClick={handleCancel}
            >
              {" "}
              Я согласен с условиями использования личного кабинета
            </Checkbox>
          </Form.Item>
          <Styles />
        </Modal>
        <BackButton size="large" onClick={onBack}>
          Назад
        </BackButton>
        <Actions>
          <SubmitButton
            htmlType="submit"
            type="primary"
            size="middle"
            loading={isSubmiting}
            disabled={isResending}
          >
            Подтвердить
          </SubmitButton>
        </Actions>
      </BaseForm>
    </>
  );
}

function useConfirm(defaultMessage: string, phone: string) {
  const confirm = useMutation(srv.pinApprove);
  const resend = useMutation(srv.pinSend);

  const onFinish = useCallback(
    (approveKey) => {
      confirm.mutate({ ...approveKey, phone });
    },
    [confirm, phone]
  );

  return {
    isSubmiting: confirm.isLoading,
    isResending: resend.isLoading,
    isSuccess: confirm.isSuccess,
    error: confirm.error || resend.error,
    message: resend.data || defaultMessage,
    onFinish,
    onResend: resend.mutate,
  };
}
