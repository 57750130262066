import React, { useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { Button, Alert, Form, Input } from "antd";
import srv from "../../../../services";
import { BaseForm } from "../../../_common";
import { SaveButton } from "./EmailSettings.styled";
interface Props {
  onFinish: (message?: string) => void;
}

export default function EmailSettings({ onFinish }: Props) {
  const { isSubmiting, error, ...formProps } = useForm(onFinish);

  return (
    <BaseForm layout="vertical" {...formProps}>
      <Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="newValue"
        label="Новый e-mail"
        rules={[{ required: true, type: "email" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      {!!error && <Alert type="error" showIcon message={error} />}

      <footer>
        <SaveButton htmlType="submit" type="primary" loading={isSubmiting}>
          Сохранить
        </SaveButton>

        <Button onClick={() => onFinish()}>Отмена</Button>
      </footer>
    </BaseForm>
  );
}

function useForm(onSuccess: Props["onFinish"]) {
  const {
    mutate: submit,
    isLoading: isSubmiting,
    isSuccess,
    error,
    data: message,
    reset,
  } = useMutation(srv.changeUserEmail);

  const onFinish = useCallback(
    (values) => {
      submit(values);
    },
    [submit]
  );

  const onValuesChange = useCallback(() => {
    if (error) reset();
  }, [error, reset]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess(message);
      reset();
    }
  }, [isSuccess, message, reset, onSuccess]);

  return { isSubmiting, error, onValuesChange, onFinish };
}
