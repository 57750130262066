import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Contracts from "./Contracts";
import PaymentDocuments from "./PaymentDocuments";
import СonnectionDocuments from "./СonnectionDocuments";
import Notifications from "./Notifications";
import { ContentNav } from "../_common";
import { useQueryClient } from "react-query";

export default function Profile() {
  const notificationNotReadCount = useQueryClient().getQueryData<any>(
    "notificationNotReadCount"
  );

  return (
    <>
      <ContentNav
        links={[
          { name: "Договоры", path: "/contracts/profile-contracts" },
          // { name: "Начисления", path: "/contracts/debts" },
          {
            name: "Запрос платежных документов",
            path: "/contracts/payment-documents",
          },
          // {
          //   name: "Документы тех.присоединения",
          //   path: "/contracts/connection-documents",
          // },
          {
            name: "Уведомления",
            note: notificationNotReadCount,
            path: "/contracts/notifications",
          },
        ]}
      />
      <Switch>
        <Route path="/contracts/profile-contracts" component={Contracts} />
        {/* <Route path="/contracts/debts" component={Debts} /> */}
        <Route
          path="/contracts/payment-documents"
          component={PaymentDocuments}
        />
        {/* <Route
          path="/contracts/connection-documents"
          component={СonnectionDocuments}
        /> */}
        <Route path="/contracts/notifications" component={Notifications} />
        <Route render={() => <Redirect to="/contracts/profile-contracts" />} />
      </Switch>
    </>
  );
}
