import styled from 'styled-components';
import * as antd from 'antd';

export const Form = styled(antd.Form)`
  width: 100%;

  header {
    h2 {
      font-size: 1.2em;
      line-height: 1.1em;
      margin-top: -0.2rem;
      margin-bottom: 1rem;
    }
  }

  section {
    margin-bottom: 1rem;

    h3 {
      font-size: 1.05em;
      margin-bottom: 0.33rem;
    }
  }

  .ant-alert {
    margin-top: 0.25rem;
    margin-bottom: -0.25rem;
  }

  label {
    display: block;
    color: #8F9091;
    font-size: 13px;
  }

  footer {
    display: flex;
    align-items: center;
    margin-top: 30px;

    > * {
      margin-right: 0.77rem;

      &:last-child {
        margin-right: 0;
      }
    }

    button[type="submit"] {
      padding-left: 2.3rem;
      padding-right: 2.3rem;
    }
  }

  .ant-form-item {
    margin-top: 10px;
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-label {
      padding-bottom: 5px;

      > label {
        height: auto;
      }
    }

    .ant-form-item:last-child {
      margin-bottom: 0!important;
    }

    &-control-input {
      min-height: auto;
    }

    &-explain {
      min-height: auto;
      margin-top: 0.2rem;
      line-height: 1.05em;
      font-size: 0.88em;
    }

    .ant-input-number {
      width: 7.6rem;
      max-width: 100%;

      &-handler-wrap {
        display: none;
      }
    }

    input:read-only {
      cursor: default;

      &:focus, &:hover {
        border-color: #d9d9d9;
        outline: 0;
        box-shadow: none;
      }
    }
  }
`;
