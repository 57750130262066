import React, { Fragment } from 'react';
// import { Switch, Route, Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';
// import { ContentNav } from '../_common';
// import AppealForm from './AppealForm';
// import AppealAskQuestion from './AppealForm/AppealAskQuestion';
// import Appeals from './TypeAppeals/Appeals/Appeals';
// import AppealsQuestion from './TypeAppeals/AppealsQuestion/AppealsQuestion';

export default function Feedback() {
  const feature = useQueryClient().getQueryData<Models.Feature>('feature');

  return (
    <>
        <h2 style={{ paddingLeft: '50px', paddingTop: '50px' }}>
            Данный сервис находится в разработке!
        </h2>
      {/*<ContentNav*/}
      {/*  links={[*/}
      {/*    // {*/}
      {/*    //   name: "Обращения",*/}
      {/*    //   path: "/feedback/appeals",*/}
      {/*    //   exact: true,*/}
      {/*    //   featured: Number(feature?.officialAppeals || feature?.aisAppeals),*/}
      {/*    // },*/}
      {/*    {*/}
      {/*      name: 'Вопросы',*/}
      {/*      path: '/feedback/appeals-question',*/}
      {/*    },*/}
      {/*    // {*/}
      {/*    //   name: "Создать обращение",*/}
      {/*    //   path: "/feedback/appeal-create",*/}
      {/*    // },*/}
      {/*    {*/}
      {/*      name: 'Задать вопрос',*/}
      {/*      path: '/feedback/appeal-create-question',*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}

      {/*<Switch>*/}
      {/*  <Route path="/feedback/appeals" component={Appeals} />*/}
      {/*  <Route path="/feedback/appeals-question" component={AppealsQuestion} />*/}
      {/*  <Route path="/feedback/appeal-create" component={AppealForm} />*/}
      {/*  <Route*/}
      {/*    path="/feedback/appeal-create-question"*/}
      {/*    component={AppealAskQuestion}*/}
      {/*  />*/}
      {/*  <Route render={() => <Redirect to="/feedback/appeals" />} />*/}
      {/*</Switch>*/}
    </>
  );
}
