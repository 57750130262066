import styled from 'styled-components';
import * as router from 'react-router-dom';

export const Nav = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 20px 0;
  min-height: 70px;
  background-color: #fff;
  border-bottom: 2px solid #f3f3f3;
  border-radius: 22px 0 0 0;

  @media (min-width: 480px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 800px) {
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1400px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 1600px) {
    padding-left: 80px;
    padding-right: 80px;
    min-height: 90px;
  }
`;

export const NavLink = styled(router.NavLink)`
  display: flex;
  align-items: stretch;
  position: relative;
  flex: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  margin-right: 22px;
  color: ${(props)=>props.theme.blueRGB};
  
  @media (min-width: 480px) {
    flex: none;
    margin-right: 33px;
  }

  @media (min-width: 800px) {
    margin-right: 50px;
  }

  @media (min-width: 1400px) {
    margin-right: 5%;
  }

  > * {
    padding: 8px 0;
  }

  b {
    color: ${(props) => props.theme.orange};
    margin-right: 7px;
  }

  u {
    position: relative;
    text-decoration: none;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${(props) => props.theme.black};
  }

  &.active u {
    color: initial;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 5px;
      bottom: -2px;
      border-radius: 5px;
      background-color: #8dc63f;
    }
  }
`;
