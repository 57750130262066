import React, { useState } from 'react';
import { Select } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import srv from '../../../services';
import { EllipseBlue } from '../../_assests';
import {
  Wrapper,
  Content,
  OptionServiceCenter,
  CitySelect,
  MapWrapper,
  MapPreview,
  MapFull,
  PictureLocation,
} from './MapContent.styled';
import { useQuery } from 'react-query';

export default function MapContent() {
  /**
   * Добавить югра регион.
   */
  const { regions, currentRegion, onChangeRegion } = useServiceCenters();
  const [mapMaximized, setMapMaximized] = useState(false);

  if (mapMaximized)
    return (
      <MapFull
        region={currentRegion}
        onMinimize={() => setMapMaximized(false)}
      />
    );

  return (
    <ReactResizeDetector handleWidth>
      {({ width }) => (
        <Wrapper>
          <Content>
            <OptionServiceCenter>
              <PictureLocation>
                <EllipseBlue />
                <b>Офисы обслуживания клиентов</b>
              </PictureLocation>
              {!!regions.length && (
                <CitySelect>
                  <label>Выберите регион</label>
                  <Select
                    value={currentRegion}
                    onChange={(val) => onChangeRegion(val)}
                  >
                    {regions.map((region) => (
                      <Select.Option key={region.id} value={region.id}>
                        {region.name}
                      </Select.Option>
                    ))}
                  </Select>
                </CitySelect>
              )}
            </OptionServiceCenter>
            <MapWrapper key={width}>
              {width > 800 ? (
                <MapPreview
                  region={currentRegion}
                  onMaximize={() => setMapMaximized(true)}
                />
              ) : (
                <MapFull region={currentRegion} />
              )}
            </MapWrapper>
          </Content>
        </Wrapper>
      )}
    </ReactResizeDetector>
  );
}

function useServiceCenters() {
  const { data: regions } = useQuery<Models.Dict[]>('regions', srv.getRegions, {
    initialData: [],
  });

  const [currentRegion, onChangeRegion] = useState(7);

  return { regions, currentRegion, onChangeRegion };
}
