import styled from 'styled-components';
import * as common from '../_common';
import * as antd from 'antd';

export const BaseForm = styled(common.BaseForm)`
  .ant-alert {
    margin-top: 30px;
    margin-bottom: -20px;
  }
`;

export const Address = styled.div`
  display: flex;
  margin: 5px 0 20px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ConnectHeader = styled.div`
  padding-left: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConnectName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #2b314f;
`;

export const ConnectPeriod = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    42.69deg,
    #fff33b -5.54%,
    #fee72e -1.35%,
    #fed51b 6.72%,
    #fdca10 15%,
    #fdc70c 23.9%,
    #679940 64.56%,
    #ed683c 87.51%,
    #679940 99.24%
  );
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
`;

export const TableAccruals = styled(Table)`
  margin: auto;
  width: 50%;
`;

export const TableHead = styled.thead`
  th {
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #a3a3a5;
    padding: 10px;
    text-align: center;
    font-weight: normal;
    vertical-align: baseline;
  }
`;
export const TableRow = styled.tr`
  td {
    padding: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    background: rgba(238, 242, 247, 0.3);

    .ant-input {
      width: 120px;
    }
  }
`;

export const TableBody = styled.tbody``;

export const TableReading = styled((props) => <antd.Table {...props} />)`
  .ant-table table {
    text-align: center;
  }
  .ant-table-thead {
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #a3a3a5;
    padding: 10px;
    font-weight: normal;
    vertical-align: baseline;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    text-align: center;
  }
  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }
`;

export const InputIndication = styled((props) => (
  <antd.InputNumber {...props} />
))`
  width: 120px;
  border: none;
  border-bottom: 2px solid #2b314f;

  .ant-input-number-input:placeholder-shown {
    font-size: 12px;
  }
`;

export const Spinner = styled((props) => <antd.Spin {...props} />)`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const SelectBlock = styled.div`
  margin-bottom: 20px;
`;

export const SelectTypeDevice = styled((props) => <antd.Select {...props} />)`
  width: 200px;
  margin-right: 20px;
`;

export const SubmitButton = styled((props) => (
  <antd.Button {...props} type="primary" size="large" />
))`
  background-color: #679940;
  display: block;
  margin-left: auto;
  margin-top: 44px;
  color: #313149;
  width: 300px;
  font-size: 18px;
  line-height: 18px;
    position: relative;
    z-index: 10001;
`;

export const Spin = styled(antd.Spin)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -18px;
  margin-left: -16px;
`;

export const Underline = styled.div`
  width: 100%;
  height: 3px;
  margin-top: 5px;
  background: #a3a3a533;
`;
